import HttpInterceptor from './HttpInterceptor'

export default {

  name: 'SageService',

  getCustomerContract(customerNo) {
    return HttpInterceptor.httpClient().get('/sage/contract/' + customerNo);
  },

  getCustomers() {
    return HttpInterceptor.httpClient().get('/sage/organization/paying');
  },

  getAllSettlements(state, startDate, endDate) {
    return HttpInterceptor.httpClient().get('sage/contract/settlements/' + state + '?startDate=' + startDate + '&endDate=' + endDate);
  },

  updateSettlementBillingState(billingNumber, state) {
    return HttpInterceptor.httpClient().put('sage/contract/settlements/' + billingNumber, '"' + state + '"');
  },

  cancelSettlement(organizationId, billingNumber) {
    return HttpInterceptor.httpClient().put('sage/organization/' + organizationId + '/settlement/' + billingNumber + '/storno', '');
  },

  createSettlement(organizationId) {
    return HttpInterceptor.httpClient().put('sage/organization/' + organizationId + '/settlement/create', '');
  },

  pushMessageTest(facilityReferenceNo) {
    return HttpInterceptor.httpClient().get('sage/info/pushtest/' + facilityReferenceNo);
  },
}
