<template>
  <div v-if="isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><i class="fi-torso-business"></i>&nbsp;&nbsp;&nbsp;Träger</h3>
      </div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">
      <div class="cell">
        <md-card>
          <md-tabs id="organization-tabs-id" md-dynamic-height @md-changed="onTabChanged">
            <md-tab id="tab-organization-overview" md-label="Übersicht">
              <div v-if="organization && organizationContract" class="cell">
                <div class="grid-x">
                  <div class="cell medium-4">
                    <md-card-media>
                      <br>
                      <img src="../assets/img/kindergarden-building.jpg" alt="Träger">
                      <div class="centered">
                        <h4>Kunden Nr : <span style="color: mintcream">{{ organization.customerNo }}</span></h4>
                      </div>
                    </md-card-media>
                  </div>
                  <div class="cell medium-8">
                    <OrganizationView :organization="organization"/>
                    <md-card-content>
                      <div v-if="organization && (isAdmin || isManagement)" class="grid-x grid-padding-x grid-padding-y">
                        <div class="cell medium-12 large-4">
                          <br>
                          <div @click="editOrganizationDialog" class="button expanded"
                               :disabled="editOrganizationDialogButtonDisabled">
                            <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Daten bearbeiten
                          </div>
                        </div>
                        <div class="clear medium-0 large-4">
                        </div>
                        <div v-if="editOrganizationDialogButtonDisabled" class="cell medium-12 large-4">
                          <br>
                          <div class="grid-x grid-padding-x">
                            <div class="cell small-2 text-left">
                              <vue-simple-spinner></vue-simple-spinner>
                            </div>
                            <div class="cell small-10"><h5>Moment bitte ... </h5></div>
                          </div>
                        </div>
                      </div>
                    </md-card-content>
                  </div>
                </div>
              </div>
            </md-tab>
            <md-tab v-if="!organization.testPhaseActive" id="tab-organization-contracts" md-label="Vertrag">
              <div class="cell">
                <br>
                <div>
                  <ContractView :layout-classes="'cell large-6'" :contract="organizationContract"
                                :organization="organization" @updateOrganizationContract="getOrganizationContract"/>
                </div>
              </div>
            </md-tab>
            <md-tab v-if="!organization.testPhaseActive && organizationContract && (organizationContract.payingCustomer || isDeleted)"
                    id="tab-organization-invoices" md-label="Rechnungen">
              <div class="cell">
                <br>
                <md-card>
                  <md-card-header>
                    <p class="md-title md-subhead" style="font-size: xx-large">Rechnungsübersicht</p><br>
                    <p class="md-title">Vertrag-Nr : <span>{{ organizationContract.contractNumber }}</span></p>
                    <p class="md-subhead">Kunden-Nr : <b>{{ organizationContract.customerNo }}</b></p>
                    <br>
                  </md-card-header>
                  <md-card-content>
                    <SettlementsView :contract="organizationContract" :layout-classes="'cell large-6'"/>
                  </md-card-content>
                </md-card>
              </div>
            </md-tab>
            <md-tab v-if="!organization.testPhaseActive && organizationContract && organizationContract.payingCustomer && !isDeleted && (isAdmin || isManagement)"
                id="tab-organization-backup" md-label="Datensicherung">
              <div class="cell">
                <br>
                <md-card>
                  <md-card-header>
                    <p class="md-title md-subhead" style="font-size: xx-large">Datensicherung</p><br>
                  </md-card-header>
                  <md-card-content>
                    <div v-if="organization.timejumpActive">
                      <h4 class="flash-purple">Zeitsprung <b>aktiv</b></h4>
                    </div>
                    <BackupView v-else :organization="organization"></BackupView>
                  </md-card-content>
                </md-card>
              </div>
            </md-tab>
          </md-tabs>
        </md-card>
      </div>
    </div>

    <EditOrganizationDialog :showDialog="showEditOrganizationDialog" :organization="selectedOrganization"
                            :mode="'update'"
                            @updateOrganizationSuccess="updateOrganizationSuccess"
                            @closeEditOrganizationDialog="showEditOrganizationDialog = false; editOrganizationDialogButtonDisabled = false">
    </EditOrganizationDialog>
  </div>
</template>


<script>
import Spinner from 'vue-simple-spinner';
import OrganizationService from '../services/OrganizationService';
import HttpErrorHandler from '../services/HttpErrorHandler';

import EditOrganizationDialog from "../components/dialogs/EditOrganizationDialog";
import AddressView from '../components/views/AddressView';
import ContactView from "../components/views/ContactView";
import PersonView from "../components/views/PersonView";
import OrganizationView from "../components/views/OrganizationView";

import Organization from "../entities/Organization";
import ContractView from "../components/views/account/ContractView";
import SettlementsView from "../components/views/account/SettlementsView";
import jQuery from "jquery";
import BackupView from "../components/views/account/BackupView";

export default {
  name: 'Organization',
  components: {
    BackupView,
    ContractView,
    SettlementsView,
    EditOrganizationDialog,
    AddressView,
    ContactView,
    PersonView,
    OrganizationView,
    Organization,
    'vue-simple-spinner': Spinner
  },

  mounted() {
    if (!this.isSage) {
      this.getOrganizationInfo();
    }
    if (this.$router.currentRoute.fullPath.toLowerCase().includes('vertrag')) {
      setTimeout(() =>
          jQuery('#organization-tabs-id').children('.md-tabs-navigation').first().children(':button.md-button').first().next().trigger('click'), 500);
    }
    if (this.$router.currentRoute.fullPath.toLowerCase().includes('rechnungen')) {
      setTimeout(() =>
          jQuery('#organization-tabs-id').children('.md-tabs-navigation').first().children(':button.md-button').first().next().next().trigger('click'), 500);
    }
  },

  data() {
    return {
      sending: false,
      showEditOrganizationDialog: false,
      selectedOrganization: null,
      success: false,
      successMsg: '',
      error: false,
      errorMsg: '',
      editOrganizationDialogButtonDisabled: false,

      organizationContract: null,
    }
  },

  methods: {

    onTabChanged(tabId) {
      if (tabId !== 'tab-organization-overview') {
        if ((this.isAdmin && !this.isFacilityAdmin) || this.isDeleted || this.isManagement || this.isTreasurer) {
          this.getOrganizationContract();
        }
      }
    },

    getOrganizationContract() {
      this.sending = true;
      OrganizationService.getOrganizationContract()
          .then(response => {
            this.organizationContract = response.data;
            this.$store.commit('organizationContract', response.data);
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Vertragsdaten für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          });
    },

    getOrganizationInfo() {
      this.sending = true;
      OrganizationService.getOrganizationInfo()
          .then(response => {
            this.$store.commit('organizationInfo', response.data);
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Trägerdaten');
            this.sending = false;
          });
    },

    editOrganizationDialog() {
      this.editOrganizationDialogButtonDisabled = true;
      setTimeout(() => this.editOrganizationDialogButtonDisabled = false, 3000);

      setTimeout(() => {
        this.selectedOrganization = JSON.parse(JSON.stringify(this.organization));

        if (this.selectedOrganization.address === null) {
          this.selectedOrganization.address = Organization.fullOrganization.address;
        }
        if (this.selectedOrganization.contact === null) {
          this.selectedOrganization.contact = Organization.fullOrganization.contact;
        }
        if (this.selectedOrganization.responsiblePerson === null) {
          this.selectedOrganization.responsiblePerson = Organization.fullOrganization.responsiblePerson;
        }

        this.showEditOrganizationDialog = true;
      }, 100);
    },

    updateOrganizationSuccess() {
      this.showEditOrganizationDialog = false;
      this.editOrganizationDialogButtonDisabled = false;
      this.$store.commit('successMsg', 'Die Änderungen für Organisation <b>' + this.selectedOrganization.name + '</b> wurden erfolgreich gespeichert!');
      this.success = true;
      this.getOrganizationInfo();
    },
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isDeleted() {
      return this.$store.getters.deleted;
    },

    isFacilityAdmin() {
      return this.$store.getters.facilityAdmin;
    },

    isTreasurer() {
      return this.$store.getters.treasurer;
    },

    isManagement() {
      return this.$store.getters.management;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    isBeta() {
      return this.$store.getters.beta;
    },

    organization() {
      return this.$store.getters.organization;
    },

    labels() {
      return this.$store.getters.labels;
    },
  }
}
</script>

<style lang="scss" scoped>
h4 {
  font-weight: 300;
}

h5 {
  font-weight: 300;
}

.centered {
  position: absolute;
  top: 85%;
  left: 50%;
  right: -40%;
  transform: translate(-35%, -50%);
}

p {
  margin: 0;
}

i {
  color: black;
}

span {
  color: cornflowerblue;
}
</style>