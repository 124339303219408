<template>
  <div v-if="organization">
    <div v-if="dataSecurityModuleActive" class="grid-x grid-padding-x grid-padding-y">
      <div class="cell large-8">
        <md-table id="backups-table-id" v-model="searched" md-sort="createdDate" md-sort-order="asc"
                  md-card @md-selected="onSelectBackup" style="height: 550px;">
          <md-table-toolbar>
            <div class="md-toolbar-section-start grid-x grid-padding-x">
              <div class="cell large-5 medium-5 small-5">
                <p class="md-title ai-number">{{ searched.length }} Datensicherung<span
                    v-if="searched.length !== 1">en</span></p>
              </div>
              <div class="cell large-1 medium-1 small-1">
                <div v-if="sending">
                  <vue-simple-spinner></vue-simple-spinner>
                </div>
              </div>
              <div class="cell large-6 medium-6 small-6">
                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Suche Dateiname" v-model="search"
                            @input="searchOnTable"/>
                </md-field>
              </div>
              <div class="cell" style="padding-bottom: 15px;">
                <div class="grid-x grid-padding-x">
                  <div class="cell small-6"></div>
                  <div class="cell small-6">
                    <md-button v-if="isAdmin" @click="onCreateBackupDialog"
                               class="md-icon-button md-raised md-primary" :disabled="sending">
                      <md-icon>save</md-icon>
                      <md-tooltip>Neue Datensicherung erstellen</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="onDownloadBackupDialog"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-third">
                      <md-icon>file_download</md-icon>
                      <md-tooltip>Datensicherung als ZIP-Datei herunterladen</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="onActivateTimeJump"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-forth">
                      <md-icon>restore</md-icon>
                      <md-tooltip>Zeitsprung aktivieren</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="onDeleteBackupDialog"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-accent">
                      <md-icon>delete</md-icon>
                      <md-tooltip>Datensicherung löschen</md-tooltip>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Keinen passenden Dateinamen gefunden"
                                :md-description="`Keinen Dateinamen mit '${search}' gefunden. Versuchen Sie es mit einem anderen Dateinamen.`">
          </md-table-empty-state>

          <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                        md-selectable="single">
            <md-table-cell md-label="Erstellt von" md-sort-by="createdBy">{{ item.createdBy }}</md-table-cell>
            <md-table-cell md-label="am" md-sort-by="createdDate">{{ item.createdDate | moment('DD.MM.YYYY') }}
            </md-table-cell>
            <md-table-cell md-label="um" md-sort-by="creationTime">{{ item.creationTime  | moment('LTS') }}
            </md-table-cell>
            <md-table-cell md-label="Datei" md-sort-by="filename">{{ item.filename }}
            </md-table-cell>
            <md-table-cell md-label="Größe" md-sort-by="sizeBytes">
              {{ parseFloat(item.sizeBytes / 1024).toFixed(2).replace('.', ',') }} kB
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <div class="cell large-4">
        <md-card style="height: 550px;">
          <md-card-header>
            <div class="md-layout">
              <div class="md-layout-item md-size-15">
                <md-icon>settings</md-icon>
              </div>
              <div class="md-layout-item">
                <div class="md-title">Einstellungen</div>
                <div class="md-subhead">Datensicherung</div>
              </div>
            </div>
          </md-card-header>
          <md-card-content v-if="backupSettings" style="height: 410px;">
            <md-checkbox v-model="backupSettings.automationActive" :disabled="sending" class="md-primary">Automatische
              Datensicherung durchführen
            </md-checkbox>
            <md-field v-if="backupSettings.automationActive">
              <label>Sicherungsintervall</label>
              <md-select v-model="backupSettings.interval" :disabled="sending || !backupSettings.automationActive">
                <md-option v-for="interval of labels.backupIntervals" :value="interval" :key="interval">
                  {{ labels.backupInterval[interval] }}
                </md-option>
              </md-select>
            </md-field>
            <md-checkbox v-model="backupSettings.passwordSet" :disabled="sending" class="md-primary">Datensicherungen
              mit Passwort schützen
              <md-tooltip>Die Datensicherungen werden mit Ihrem angegebenen Passwort verschlüsselt.<br>
                Das Passwort wird dabei nicht im System gespeichert.<br>
                <b style="color: orange">Bitte stellen Sie sicher, dass Sie das Passwort nicht vergessen!</b>
              </md-tooltip>
            </md-checkbox>
            <div v-if="backupSettings.passwordSet" class="grid-x" style="width: 100%">
              <div class="cell small-4 medium-2 large-4 text-left">
                <label>Passwort
                  <span v-if="passwordScore !== null && passwordScore < 3" style="color: orange; font-size: 12px;"><br>Passwort schwach</span>
                </label>
                <md-tooltip>Mit diesem Passwort werden Ihre Datensicherungen verschlüsselt und sind damit geschützt.<br><b
                    style="color: orange">Bitte stellen Sie sicher, dass Sie das Passwort nicht vergessen!</b><br>
                  Bei der Wiederherstellung einer Datensicherung <b>muss dieses Passwort angegeben</b> werden!
                </md-tooltip>
              </div>
              <div class="cell small-8 medium-10 large-8 text-right">
                <password id="password" v-model="backupSettings.initialPassword" :toggle="true"
                          :secureLength="webAppSettings.pwdSecureLength" @score="showPasswordScore"
                          style="max-width: 100%;" :disabled="sending"/>
              </div>
            </div>
          </md-card-content>
          <div v-if="backupSettings" class="text-left" style="padding-left: 1rem">
            <button @click="onSaveBackupSettings" class="button success" :disabled="sending">
              <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Einstellungen speichern
            </button>
          </div>
        </md-card>
      </div>
    </div>
    <div v-else-if="dataSecurityModule">
      <p>Mit dem Modul <b>{{dataSecurityModule.label}}</b> haben Sie die Möglichkeit, regelmäßige Sicherungen ihrer Daten für den Träger inklusive aller Einrichtungen zu
        erstellen.</p>
      <h5><b class="ai-warn-text">Hinweis!</b></h5>

      <p>
        <span class="ai-standard-title">Ihr Vertrag <b>enthält nicht</b> unser Modul <b>{{
            dataSecurityModule.label
          }}</b></span>!<br>
        Dieses Modul
        {{ dataSecurityModule.description.charAt(0).toLowerCase() }}{{ dataSecurityModule.description.slice(1) }}
        Sie können das Modul einfach bei Ihren <span class="ai-standard-title"><a
          @click="onOpenContract">Vertragdaten</a></span> bestellen und sofort nutzen.
      </p>

      <br>
      <a @click="onOrderDataSecurity"><p>Ich möchte das Modul <span
          class="ai-standard-title"><b>{{ dataSecurityModule.label }}</b></span> verwenden und jetzt bestellen.</p></a>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showCreateBackupDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon v-if="!dataSecurityModuleActive"><i class="fi-download"></i></md-icon>
          <md-icon v-else>save</md-icon>
          &nbsp;Neue Datensicherung&nbsp;für Träger <span class="group-title">{{ organization.name }}</span>
          <span v-if="!dataSecurityModuleActive"> als Download bereitstellen</span>
          <span v-else> erstellen</span>
        </md-dialog-title>
        <div class="cell text-center" style="padding-left: 2rem; padding-right: 2rem;">
          <md-content v-if="!sending">Soll für den Träger <span class="group-title">{{ organization.name }}</span> eine
            neue
            Datensicherung
            <span v-if="!dataSecurityModuleActive"> als Download bereitstellt </span>
            <span v-else> erstellt </span>werden?
          </md-content>
          <md-content v-else class="text-center">Datensicherung wird durchgeführt, <span class="group-title">bitte warten ...</span>
            <span v-if="!dataSecurityModuleActive"> Download startet anschließend.</span>
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>

        <div class="cell text-center">
          <button @click="onCreateBackup" class="button success" :disabled="sending">
            <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, Datensicherung jetzt erstellen
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="showCreateBackupDialog = false"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showCreateBackupDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteBackupDialog">
      <div class="grid-x grid-padding-x grid-padding-y" v-if="selected">
        <md-dialog-title>
          <md-icon>delete</md-icon>&nbsp;Datensicherung&nbsp;für Träger <span class="group-title">{{
            organization.name
          }}</span> löschen
        </md-dialog-title>
        <div class="cell text-center" style="padding-left: 2rem; padding-right: 2rem;">
          <md-content>Soll die Datensicherung vom
            <span class="group-title">{{ selected.createdDate | moment('DD.MM.YYYY') }}</span> um
            <span class="group-title">{{ selected.creationTime | moment('LTS') }}</span> wirklich gelöscht werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>

        <div class="cell text-center">
          <button @click="onDeleteBackup" class="button alert" :disabled="sending">
            <i class="fi-delete"></i>&nbsp;&nbsp;&nbsp;Ja, Datensicherung jetzt löschen
          </button>
          <button class="button" style="margin-left: 1rem;" @click="showDeleteBackupDialog = false"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteBackupDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDownloadBackupDialog">
      <div class="grid-x grid-padding-x grid-padding-y" v-if="selected">
        <md-dialog-title>
          <md-icon>file_download</md-icon>&nbsp;Datensicherung&nbsp;für Träger <span
            class="group-title">{{ organization.name }}</span> herunterladen
        </md-dialog-title>
        <div class="cell text-center" style="padding-left: 2rem; padding-right: 2rem;">
          <md-content>Soll die Datensicherung vom
            <span class="group-title">{{ selected.createdDate | moment('DD.MM.YYYY') }}</span> um
            <span class="group-title">{{ selected.creationTime | moment('LTS') }}</span> wirklich heruntergeladen
            werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>

        <div class="cell text-center">
          <button @click="onDownloadBackup" class="button success" :disabled="sending">
            <i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Ja, Datensicherung jetzt herunterladen
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="showDownloadBackupDialog = false"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
          </button>
        </div>
        <button class="close-button" type="button" @click="showDownloadBackupDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showActivateTimeJumpDialog">
      <div class="grid-x grid-padding-x grid-padding-y" v-if="selected">
        <md-dialog-title>
          <md-icon>restore</md-icon>&nbsp;Zeitsprung&nbsp;für Träger <span class="group-title">{{
            organization.name
          }}</span> aktivieren
        </md-dialog-title>
        <div class="cell text-center" style="padding-left: 2rem; padding-right: 2rem;">
          <md-content class="text-left">
            Dabei wird der Datenbestand aus dieser Datensicherung sichtbar und kann eingesehen werden.
            Änderungen an diesen Daten sind nicht möglich.
            Der Zeitsprung kann jederzeit deaktiviert werden, um in den Normalbetrieb zurückzuwechseln.
          </md-content>
          <br><br>
          <md-content>Soll der Zeitsprung zu dem Zeitpunkt
            <span class="group-title">{{ selected.createdDate | moment('DD.MM.YYYY') }}</span> um
            <span class="group-title">{{ selected.creationTime | moment('LTS') }}</span> durchgeführt werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button @click="onActivateTimeJumpBackup" class="button success" :disabled="sending">
            <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Ja, Zeitsprung jetzt aktivieren
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="showActivateTimeJumpDialog = false"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
          </button>
        </div>
        <button class="close-button" type="button" @click="showActivateTimeJumpDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>
</template>

<script>
import BackupService from '../../../services/BackupService';
import HttpErrorHandler from '../../../services/HttpErrorHandler';
import Vue from 'vue';
import jQuery from 'jquery';
import vue2Dropzone from 'vue2-dropzone';
import Spinner from 'vue-simple-spinner';
import Password from 'vue-password-strength-meter';

const toLower = text => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.filename).includes(toLower(term)));
  }

  return items;
};

export default {
  name: 'BackupView',

  components: {
    vueDropzone: vue2Dropzone,
    'vue-simple-spinner': Spinner,
    Password,
  },

  props: {
    organization: {
      required: false
    },
  },

  mounted() {
    this.reloadBackups();
    this.buildUploadUrl();
  },

  watch: {
    organization() {
      this.reloadBackups();
    },
  },

  beforeDestroy() {
    this.cleanBackups();
    this.backupUrl = null;
  },

  data: () => ({
    backups: [],
    searched: [],
    selected: null,
    search: '',
    sending: false,
    showCreateBackupDialog: false,
    showDeleteBackupDialog: false,
    showDownloadBackupDialog: false,
    showActivateTimeJumpDialog: false,

    backupUrl: null,
    backupLink: null,
    backupBlob: null,

    backupSettings: null,
    passwordScore: null,

    options: {
      url: '',
      maxFilesize: 10.0, // 1024 KB
      headers: {
        'accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
      },
      createImageThumbnails: false,
      thumbnailWidth: 250,
      thumbnailHeight: 50,
      previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
      dictDefaultMessage: 'ZIP-Datei auswählen',
      acceptedFiles: '.zip',
    },
  }),

  methods: {
    showPasswordScore(score) {
      this.passwordScore = score;
    },

    getClass: ({id, active}) => ({
      'md-primary': id === id,
      'inactive-child': active === false,
    }),

    buildUploadUrl() {
      this.options.url = '';
      setTimeout(() => this.options.url = this.$store.getters.serverUrl + 'backup/upload', 200);
    },

    reloadBackups() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {

          if (this.dataSecurityModuleActive) {
            this.getBackupSettings();
            this.getBackups();
          }

          clearInterval(reloadIntervalId);

        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden der Datensicherungen');
          }
        }
      }, 250);
    },

    getBackups() {
      this.sending = true;
      BackupService.getAvailableBackups(this.organization.customerNo)
          .then(response => {
            this.backups = response.data;
            this.sending = false;
            this.searchOnTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Datensicherungen für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;

            this.backups = [
              {
                "id": "1",
                "createdDate": "2021-06-13T07:38:21.815Z",
                "createdBy": "string",
                "lastModifiedBy": "string",
                "lastModifiedDate": "2021-06-13T07:38:21.815Z",
                "customerNo": "string",
                "archiveId": "string",
                "filename": "file1",
                "creationTime": "2021-06-13T07:38:21.815Z",
                "sizeBytes": 0,
                "comment": "string",
                "encrypted": true,
                "facilities": {
                  "additionalProp1": "string",
                  "additionalProp2": "string",
                  "additionalProp3": "string"
                }
              }, {
                "id": "2",
                "createdDate": "2021-06-13T07:38:21.815Z",
                "createdBy": "string",
                "lastModifiedBy": "string",
                "lastModifiedDate": "2021-06-13T07:38:21.815Z",
                "customerNo": "string",
                "archiveId": "string",
                "filename": "file2",
                "creationTime": "2021-06-13T07:38:21.815Z",
                "sizeBytes": 0,
                "comment": "string",
                "encrypted": true,
                "facilities": {
                  "additionalProp1": "string",
                  "additionalProp2": "string",
                  "additionalProp3": "string"
                }
              },
            ];

            this.searchOnTable();
          })
    },

    getBackupSettings() {
      this.sending = true;
      BackupService.getSettings()
          .then(response => {
            this.backupSettings = response.data;
            if (!this.backupSettings.interval) {
              this.backupSettings.interval = this.labels.backupIntervals[1];
            }
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Datensicherungseinstellungen für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          })
    },

    searchOnTable() {
      this.searched = this.backups;
      this.searched = searchByName(this.searched, this.search);
    },

    onOpenContract() {
      jQuery('#organization-tabs-id').children('.md-tabs-navigation').first().children(':button.md-button').first().next().trigger('click');
    },

    onOrderDataSecurity() {
      this.onOpenContract();
      setTimeout(() => {
        jQuery('#' + this.dataSecurityModule.id).trigger('click');
      }, 500);
    },

    onSelectBackup(item) {
      this.selected = item;
      if (this.selected) {
        this.selectedBillingState = '';
      }
    },

    onCreateBackupDialog() {
      this.showCreateBackupDialog = true;
    },

    onDeleteBackupDialog() {
      this.showDeleteBackupDialog = true;
    },

    onDownloadBackupDialog() {
      this.showDownloadBackupDialog = true;
    },

    onActivateTimeJump() {
      this.showActivateTimeJumpDialog = true;
    },

    onCreateBackup() {
      if (this.dataSecurityModuleActive) {
        this.createBackup();
      } else {
        this.createBackupAndDownload();
      }
    },

    createBackup() {
      this.sending = true;
      BackupService.createBackup(this.organization.customerNo)
          .then(() => {
            this.sending = false;
            this.showCreateBackupDialog = false;
            this.$store.commit('successMsg', 'Die Datensicherung für Träger <b>' + this.organization.name + '</b> wurde erfolgreich erstellt.');
            this.getBackups();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erstellen einer neuen Datensicherung für Träger <b>' + this.organization.name + '</b>');
            this.showCreateBackupDialog = false;
            this.sending = false;
          })
    },

    createBackupAndDownload() {
      this.sending = true;
      BackupService.createBackupAndDownload(this.organization.customerNo)
          .then(response => {
            this.sending = false;
            this.showCreateBackupDialog = false;
            this.acceptNoBackupModuleDisclaimer = false;

            if (!this.dataSecurityModuleActive) {
              setTimeout(() => {
                this.cleanBackups();
                this.backupBlob = new Blob([response.data], {type: 'application/octet-stream'});
                this.backupUrl = window.URL.createObjectURL(this.backupBlob);
                this.backupLink = document.createElement('a');
                const id = Vue.moment().format('YYYYMMDD-LTS').replaceAll(':', '') + '-' + this.organization.name.replaceAll(' ', '_') + '-Backup';
                this.backupLink.href = this.backupUrl;
                this.backupLink.setAttribute('download', id + '.zip');
                this.backupLink.setAttribute('id', id);
                document.body.appendChild(this.backupLink);
                this.backupLink.click();
              }, 250);
            }

            this.$store.commit('successMsg', 'Die Datensicherung für Träger <b>' + this.organization.name + '</b> wurde erfolgreich erstellt und heruntergeladen.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erstellen einer neuen Datensicherung für Träger <b>' + this.organization.name + '</b>');
            this.showCreateBackupDialog = false;
            this.acceptNoBackupModuleDisclaimer = false;
            this.sending = false;
          })
    },

    onDeleteBackup() {
      if (this.selected) {
        this.deleteBackup(this.selected);
      }
    },

    deleteBackup(backupItem) {
      this.sending = true;
      let timeStamp = '<b>' + Vue.moment(this.selected.createdDate).format('DD.MM.YYYY') + '</b> um <b>' + Vue.moment(this.selected.creationTime).format('LTS') + '</b>';
      BackupService.deleteBackup(this.organization.customerNo, backupItem)
          .then(() => {
            this.sending = false;
            this.showDeleteBackupDialog = false;
            this.$store.commit('successMsg', 'Die Datensicherung vom ' + timeStamp + ' wurde erfolgreich gelöscht.');
            this.getBackups();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Datensicherung vom ' + timeStamp);
            this.showDeleteBackupDialog = false;
            this.sending = false;
          })
    },

    onDownloadBackup() {
      if (this.selected) {
        this.downloadBackup(this.selected);
      }
    },

    downloadBackup(backupItem) {
      this.sending = true;
      let timeStamp = '<b>' + Vue.moment(this.selected.createdDate).format('DD.MM.YYYY') + '</b> um <b>' + Vue.moment(this.selected.creationTime).format('LTS') + '</b>';
      BackupService.downloadBackup(this.organization.customerNo, backupItem)
          .then((response) => {
            this.sending = false;
            this.showDownloadBackupDialog = false;

            setTimeout(() => {
              this.cleanBackups();
              this.backupBlob = new Blob([response.data], {type: 'application/octet-stream'});
              this.backupUrl = window.URL.createObjectURL(this.backupBlob);
              this.backupLink = document.createElement('a');
              const id = this.selected.filename;
              this.backupLink.href = this.backupUrl;
              this.backupLink.setAttribute('download', id);
              this.backupLink.setAttribute('id', id);
              document.body.appendChild(this.backupLink);
              this.backupLink.click();
            }, 250);

            this.$store.commit('successMsg', 'Die Datensicherung vom ' + timeStamp + ' wurde erfolgreich heruntergeladen.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Herunterladen der Datensicherung vom ' + timeStamp);
            this.showDownloadBackupDialog = false;
            this.sending = false;
          })
    },

    onSaveBackupSettings() {
      this.saveBackupSettings();
    },

    saveBackupSettings() {
      this.sending = true;
      BackupService.updateSettings(this.backupSettings)
          .then(response => {
            this.backupSettings = response.data;
            this.sending = false;
            this.$store.commit('successMsg', 'Die Einstellungen wurden erfolgreich gespeichert.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Datensicherungseinstellungen für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          })
    },

    onActivateTimeJumpBackup() {
      this.activateTimeJump(this.selected);
    },

    activateTimeJump(backupItem) {
      this.sending = true;
      BackupService.toggleTimeJump(backupItem)
          .then((response) => {
            this.sending = false;
            this.showActivateTimeJumpDialog = false;
            this.$store.commit('successMsg', 'Der Zeitsprung für Träger <b>' + this.organization.name + '</b> wurde erfolgreich aktiviert');
            this.$store.commit('organizationInfo', response.data);
            setTimeout(() => this.$router.go(0), 3000);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Aktivieren des Zeitsprungs für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          })
    },

    cleanBackups() {
      if (this.backupUrl) {
        this.backupBlob = null;
        document.body.removeChild(this.backupLink);
        this.backupLink = null;
      }
    },
  },

  computed: {
    dataAvailable() {
      return this.organization && this.organization.allowedModules;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    labels() {
      return this.$store.getters.labels
    },

    modules() {
      return this.$store.getters.modules
    },

    webAppSettings() {
      return this.$store.getters.webAppSettings
    },

    dataSecurityModule() {
      let module = null;
      this.modules.forEach(mod => {
        if (mod.label.indexOf(this.labels.moduleType['DATA_SECURITY']) >= 0) {
          module = mod;
        }
      })
      return module;
    },

    dataSecurityModuleActive() {
      if (this.dataAvailable) {
        return this.organization.dataSecurityModuleActive || this.organization.allowedModules.includes('DATA_SECURITY');
      } else {
        return false;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: unset;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: normal;
}

.md-tooltip b {
  color: orange;
}
</style>
