<template>
  <div class="grid-x grid-padding-x grid-padding-y">
    <div v-if="contract || settlements" class="cell">

      <div class="grid-x grid-padding-x">
        <div :class="layoutClasses">
          <md-table id="settlements-table-id" v-model="searchedSettlements" md-sort="billingNumber" md-sort-order="asc"
                    md-card md-fixed-header @md-selected="onSelectSettlement"
                    style="min-height: 539px; height: 100%;">
            <md-table-toolbar>
              <div class="md-toolbar-section-start grid-x grid-padding-x">
                <div class="cell large-6 medium-6 small-6">
                  <p class="md-title ai-number">{{searchedSettlements.length}} Rechnung<span v-if="searchedSettlements.length !== 1">en</span></p>
                </div>
                <div class="cell large-6 medium-6 small-6">
                  <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Suche Betrag" v-model="search"
                              @input="searchOnSettlementsTable"/>
                  </md-field>
                </div>
              </div>
            </md-table-toolbar>

            <md-table-empty-state v-if="search"
                                  md-label="Keinen Betrag gefunden"
                                  :md-description="`Keinen Betrag über '${search}' gefunden. Versuchen Sie es mit einem anderen Wert.`">
            </md-table-empty-state>

            <md-table-row :id="item.billingNumber" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single" >
              <md-table-cell md-label="Rechnungs-Nr" md-sort-by="billingNumber">{{ item.billingNumber }}
                <md-tooltip md-delay="250">
                  <p v-if="organizations" class="md-subhead">Kunden-Nr : <b>{{item.customerNo}}</b></p>
                  <p v-if="organizations" class="md-subhead">Kunde : <b>{{getOrganizationName(item.customerNo)}}</b></p>
                  <p v-if="organizations" class="md-subhead">Organisationsform : <b>{{getOrganizationType(item.customerNo)}}</b></p>
                  <p v-if="organizations" class="md-subhead">Verantwortlich : <b>{{getResponsiblePersonName(item.customerNo)}}</b></p>
                  <p v-if="organizations" class="md-subhead">Module : <b>{{getModules(item.customerNo)}}</b></p>
                </md-tooltip>
              </md-table-cell>
              <md-table-cell md-label="Zeitraum von" md-sort-by="billingPeriodStart">{{ item.billingPeriodStart | moment('DD.MM.YYYY')}}</md-table-cell>
              <md-table-cell md-label="Zeitraum bis" md-sort-by="billingPeriodEnd">{{ item.billingPeriodEnd  | moment('DD.MM.YYYY')}}</md-table-cell>
              <md-table-cell md-label="Betrag" md-sort-by="dueAmountVAT">{{ item.dueAmountVAT / 100 | currency }}</md-table-cell>
              <md-table-cell md-label="Status" md-sort-by="billingState">{{ labels.billingState[item.billingState] }}</md-table-cell>
              <md-table-cell v-if="isSage" md-label="Mahnstufe" md-sort-by="reminderLevel">{{ item.reminderLevel }}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>

        <div v-if="layoutClasses.indexOf('large-6') < 0" style="padding: 1rem"></div>
        <div :class="layoutClasses">
          <md-card id="settlement-view-card-id" v-if="selectedSettlement">
            <md-card-header>
              <p class="md-title">Rechnungs-Nr : <span>{{selectedSettlement.billingNumber}}</span></p><br>
              <p v-if="contract" class="md-subhead">Vertrag-Nr : <b>{{contract.contractNumber}}</b></p>
              <p class="md-subhead">Kunden-Nr : <b>{{selectedSettlement.customerNo}}</b></p>
              <p v-if="organizations" class="md-subhead">Kunde : <b>{{getOrganizationName(selectedSettlement.customerNo)}}</b></p>
              <p v-if="organizations" class="md-subhead">Organisationsform : <b>{{getOrganizationType(selectedSettlement.customerNo)}}</b></p>
              <p v-if="organizations" class="md-subhead">Verantwortlich : <b>{{getResponsiblePersonName(selectedSettlement.customerNo)}}</b></p>
              <p v-if="organizations" class="md-subhead">Module : <b>{{getModules(selectedSettlement.customerNo)}}</b></p><br>
              <p class="md-content">Rechnungs-Datum : <span>{{selectedSettlement.invoiceDate | moment('DD.MM.YYYY')}}</span></p>
              <p class="md-content">Status : <b :style="getBillingStateStyle(selectedSettlement.billingState)">{{labels.billingState[selectedSettlement.billingState]}}</b> <span v-if="selectedSettlement.billingState === 'OPEN'">fällig zum <span>{{selectedSettlement.dueDate | moment('DD.MM.YYYY')}}</span></span></p>
              <p class="md-content" v-if="selectedSettlement.reminderLevel > 0">Mahnstufe : <b :style="getBillingStateStyle(selectedSettlement.billingState)">{{labels.reminderLevel[selectedSettlement.reminderLabel]}}</b></p>
              <p v-if="selectedSettlement.interim" class="md-content"><span>{{selectedSettlement.maxNumChildren}}</span> Kinder anteilig für <span>{{selectedSettlement.interimDays}}</span> Tage</p>
              <p v-else class="md-content">maximale Anzahl Kinder : <span>{{selectedSettlement.maxNumChildren}}</span></p>
              <p class="md-content">Zeitraum von <span>{{selectedSettlement.billingPeriodStart | moment('DD.MM.YYYY')}}</span> bis <span>{{selectedSettlement.billingPeriodEnd | moment('DD.MM.YYYY')}}</span></p>
              <div v-if="settlements" class="grid-x grid-padding-x grid-padding-x">
                <div class="cell large-6 medium-8 small-10">
                  <br>
                  <md-field style="width: 250px">
                    <label>Rechnungs-Status setzen auf</label>
                    <md-select v-model="selectedBillingState" @md-selected="onUpdateBillingState"
                               :disabled="sending">
                      <md-option v-for="billingState of labels.billingStates" v-if="billingState !== 'CANCELED'" :value="billingState" :key="billingState">
                        {{labels.billingState[billingState]}}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div v-if="selectedSettlement.billingState === 'CANCELED'" class="cell large-4 medium-4 small-4">
                  <br><br>
                  <md-button class="md-icon-button md-raised md-primary" style="background: seagreen" :disabled="sending"
                             @click="confirmCreateSettlement = true; absolutelySure = false;">
                    <md-icon>autorenew</md-icon>
                    <md-tooltip md-direction="bottom" style="font-size: medium;">
                      Rechnung <b>{{selectedSettlement.billingNumber}}</b> neu erstellen
                    </md-tooltip>
                  </md-button>
                </div>
                <div v-else class="cell large-4 medium-4 small-4">
                  <br><br>
                  <md-button class="md-icon-button md-raised md-primary" style="background: orangered" :disabled="sending"
                             @click="confirmCancelSettlement = true; absolutelySure = false;">
                    <md-icon>undo</md-icon>
                    <md-tooltip md-direction="bottom" style="font-size: medium;">
                      Rechnung <b>{{selectedSettlement.billingNumber}}</b> stornieren
                    </md-tooltip>
                  </md-button>
                </div>
                <div class="cell large-2 medium-4 small-2 text-left">
                  <vue-simple-spinner v-if="sending" style="width: 50px; padding-top: 50px;"></vue-simple-spinner>
                </div>
              </div>
            </md-card-header>
            <md-card-content>
              <table class="hover">
                <thead>
                <tr>
                  <td><b>Position</b></td>
                  <td><b>Datum</b></td>
                  <td><b>Preis</b></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) of selectedSettlement.clearingItems" :key="index">
                  <td>{{item.label}}</td>
                  <td><p><span>{{item.date | moment("DD.MM.YYYY")}}</span></p></td>
                  <td><p>
                    <span v-if="!item.debit">{{-1 * item.amountCent / 100 | currency}}</span>
                    <span v-else>{{item.amountCent / 100 | currency}}</span>
                  </p></td>
                </tr>
                </tbody>
              </table>
              <br>
              <div class="grid-x">
                <div class="cell large-6 medium-6">
                  <div @click="confirmDownloadSettlementPDF = true" class="button success" :disabled="sending">
                    <i class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;Rechnungs-PDF
                    <md-tooltip>
                      Rechnung für Zeitraum von <b>{{selectedSettlement.billingPeriodStart | moment('DD.MM.YYYY')}}</b> bis <b>{{selectedSettlement.billingPeriodEnd | moment('DD.MM.YYYY')}}</b> als PDF herunterladen.
                    </md-tooltip>
                  </div>
                </div>
                <div class="cell large-6 medium-6 text-right">
                  <p class="md-subhead">netto : <span>{{selectedSettlement.dueAmount / 100 | currency}}</span></p>
                  <p class="md-subhead">Umsatzsteuer : <span>{{ (selectedSettlement.dueAmountVAT - selectedSettlement.dueAmount) / 100 | currency}}</span></p>
                  <br>
                  <p class="md-content ai-number">Gesamt : <span>{{selectedSettlement.dueAmountVAT / 100 | currency}}</span></p>
                  <p class="md-subhead">fällig zum : <span>{{selectedSettlement.dueDate | moment("DD.MM.YYYY")}}</span></p>
                </div>
              </div>
            </md-card-content>
          </md-card>
          <div v-else>
            <br><h5><span style="color: lightseagreen;"><i>Bitte wählen Sie eine Rechnung aus der Liste</i></span></h5>
          </div>
        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" v-if="selectedSettlement" :md-active.sync="confirmDownloadSettlementPDF">
        <div class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <p><i class="fi-page-multiple"></i>&nbsp;&nbsp;&nbsp;Rechnung <span><b>{{selectedSettlement.billingNumber}}</b></span> als PDF herunterladen</p>
          </md-dialog-title>
          <div class="cell">
            <md-content>
              <p>Soll die Rechnung für den Zeitraum vom <span><b>{{selectedSettlement.billingPeriodStart | moment('DD.MM.YYYY')}}</b></span> bis
                <span><b>{{selectedSettlement.billingPeriodEnd | moment('DD.MM.YYYY')}}</b></span> als PDF heruntergeladen werden?
              </p>
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button :disabled="sending" @click="onDownloadSettlementPdf" class="button success"><i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Ja,
              PDF herunterladen
            </button>
            <button :disabled="sending" @click="confirmDownloadSettlementPDF = false"
                    class="button alert"
                    style="margin-left: 1rem;"
                    type="button">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button @click="confirmDownloadSettlementPDF = false" class="close-button" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" v-if="settlements && selectedSettlement" :md-active.sync="confirmCancelSettlement">
        <div class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <p><md-icon style="color: black;">undo</md-icon>&nbsp;&nbsp;&nbsp;Rechnung <span><b>{{selectedSettlement.billingNumber}}</b></span> stornieren</p>
          </md-dialog-title>
          <div class="cell">
            <md-content>
              <p>Soll die Rechnung <span><b>{{selectedSettlement.billingNumber}}</b></span> für den Zeitraum vom <span><b>{{selectedSettlement.billingPeriodStart | moment('DD.MM.YYYY')}}</b></span> bis
                <span><b>{{selectedSettlement.billingPeriodEnd | moment('DD.MM.YYYY')}}</b></span> in Höhe von <span><b>{{selectedSettlement.dueAmountVAT / 100 | currency}}</b></span> wirklich storniert werden?
              </p>
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
          </div>
          <div class="cell text-center">
            <button :disabled="sending || !absolutelySure" @click="onCancelSettlement" class="button alert"><md-icon style="color: black; font-size: 16px!important; height: 12px;">undo</md-icon>&nbsp;&nbsp;&nbsp;Ja,
              Rechnung stornieren
            </button>
            <button :disabled="sending" @click="confirmCancelSettlement = false"
                    class="button success"
                    style="margin-left: 1rem;"
                    type="button">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button @click="confirmCancelSettlement = false" class="close-button" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" v-if="settlements && selectedSettlement" :md-active.sync="confirmCreateSettlement">
        <div class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <p><md-icon style="color: black;">autorenew</md-icon>&nbsp;&nbsp;&nbsp;Rechnung <span><b>{{selectedSettlement.billingNumber}}</b></span> neu erzeugen</p>
          </md-dialog-title>
          <div class="cell">
            <md-content>
              <p>Soll die Rechnung <span><b>{{selectedSettlement.billingNumber}}</b></span> für den Zeitraum vom <span><b>{{selectedSettlement.billingPeriodStart | moment('DD.MM.YYYY')}}</b></span> bis
                <span><b>{{selectedSettlement.billingPeriodEnd | moment('DD.MM.YYYY')}}</b></span> neu erzeugt werden?
              </p>
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
          </div>
          <div class="cell text-center">
            <button :disabled="sending || !absolutelySure" @click="onCreateSettlement" class="button success"><md-icon style="color: black; font-size: 16px!important; height: 12px;">autorenew</md-icon>&nbsp;&nbsp;&nbsp;Ja,
              Rechnung neu erzeugen
            </button>
            <button :disabled="sending" @click="confirmCreateSettlement = false"
                    class="button alert"
                    style="margin-left: 1rem;"
                    type="button">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button @click="confirmCreateSettlement = false" class="close-button" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

    </div>
    <div v-else class="cell">
      <p class="md-title md-subhead">Keine Rechnungen verfügbar</p><br>
    </div>
  </div>
</template>

<script>

import jQuery from 'jquery';
import Spinner from 'vue-simple-spinner'
import BinaryService from "../../../services/BinaryService";
import HttpErrorHandler from "../../../services/HttpErrorHandler";
import Vue from 'vue';
import SageService from "../../../services/SageService";

const toLower = text => {
  return text.toString().toLowerCase();
};

const searchSettlementByDueAmountVAT = (items, term) => {
  if (term) {
    return items.filter(item => toLower(parseFloat((item.dueAmountVAT / 100)).toFixed(2).toString().replace('.', ',')).includes(toLower(term)));
  }

  return items;
};

export default {
  name: 'SettlementsView',
  components: {
    'vue-simple-spinner': Spinner,
  },

  props: {
    contract: {
      required: false
    },
    settlements: {
      required: false
    },
    organizations: {
      required: false
    },
    layoutClasses: {
      required: true,
    },
  },

  created() {
    this.searchOnSettlementsTable();
  },

  watch: {
    contract() {
      this.selectedSettlement = null;
      this.searchOnSettlementsTable();
    },

    settlements() {
      this.searchOnSettlementsTable();
      this.adjustTableContentHeight();
    },
  },

  data() {
    return {
      selectedSettlement: null,
      searchedSettlements: [],
      search: '',
      selectedBillingState: '',

      sending: false,
      confirmDownloadSettlementPDF: false,
      confirmCancelSettlement: false,
      confirmCreateSettlement: false,

      absolutelySure: false,

      settlementPdf: {
        url: null,
        link: null,
        blob: null,
      },
    }
  },

  methods: {
    booleanText(value) {
      return value ? 'ja' : 'nein';
    },

    getClass: ({id, active}) => ({
      'md-primary': id === id,
      'inactive-child': active === false,
    }),

    adjustTableContentHeight() {
      let content = jQuery('.md-content.md-table-content.md-scrollbar');
      content.css('max-height', '')
      content.height('100%');
    },

    getBillingStateStyle(state) {
      return  state === 'OPEN' ? 'color: red' : 'color: green';
    },

    getOrganizationName(customerNo) {
      let orgName = '';
      this.organizations.forEach(org => { if (org.customerNo === customerNo) {orgName = org.name}});
      return orgName;
    },

    getOrganizationType(customerNo) {
      let orgType = '';
      this.organizations.forEach(org => { if (org.customerNo === customerNo) {orgType = this.labels.organizationType[org.organizationType]}});
      return orgType;
    },

    getOrganizationId(customerNo) {
      let orgId = '';
      this.organizations.forEach(org => { if (org.customerNo === customerNo) {orgId = org.id}});
      return orgId;
    },

    getResponsiblePersonName(customerNo) {
      let personName = '';
      this.organizations.forEach(org => { if (org.customerNo === customerNo && org.responsiblePerson) {personName = org.responsiblePerson.fullName}});
      return personName;
    },

    getModules(customerNo) {
      let modules = '';
      this.organizations.forEach(org => {
        if (org.customerNo === customerNo) {
          org.allowedModules.forEach(module => {modules += this.labels.moduleType[module] + ' ';})
        }
      });
      return modules;
    },

    searchOnSettlementsTable() {
      if (this.contract && this.contract.settlements) {
        this.searchedSettlements = searchSettlementByDueAmountVAT(this.contract.settlements, this.search);
      } else
      if (this.settlements) {
        this.searchedSettlements = searchSettlementByDueAmountVAT(this.settlements, this.search);
      }

      let sum = 0;
      this.searchedSettlements.forEach(settlement => sum += settlement.dueAmountVAT)
      sum = sum / 100;
      this.$emit("update-sum", sum);
    },

    onSelectSettlement(item) {
      this.selectedSettlement = item;
      if (this.selectedSettlement) {
        this.selectedBillingState = '';
        this.$emit("update-selected-settlement", this.selectedSettlement);
        if (this.layoutClasses.indexOf('large-6') >= 0) {
          setTimeout(() => jQuery('#settlements-table-id').height(jQuery('#settlement-view-card-id').height()), 5);
        }
      }
    },

    onDownloadSettlementPdf() {
      this.downloadSettlementPdf(this.selectedSettlement.invoicePDFKey, this.settlementPdf);
    },

    downloadSettlementPdf(key, pdf) {
      this.sending = true;
      BinaryService.getInvoicePdf(key)
        .then(response => {
          this.sending = false;
          setTimeout(() => {
            pdf.blob = new Blob([response.data, 'utf-8'], {type: "application/pdf"});
            pdf.url = window.URL.createObjectURL(pdf.blob);

            if (pdf.link) {
              document.body.removeChild(pdf.link);
            }
            pdf.link = document.createElement('a');
            const id = this.organization.name + ' (' + this.selectedSettlement.customerNo + ') Rechnungs-Nr ' + this.selectedSettlement.billingNumber + ' Rechnungs-Datum ' + Vue.moment(this.selectedSettlement.invoiceDate).format('DD-MM-YYYY');
            pdf.link.href = pdf.url;
            pdf.link.setAttribute('download', id + '.pdf');
            pdf.link.setAttribute('id', id);
            document.body.appendChild(pdf.link);
            pdf.link.click();

            this.confirmDownloadSettlementPDF = false;
          }, 50);
        })
        .catch(e => {
          this.sending = false;
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden des PDF für Rechnungs-Nr <b>' + this.selectedSettlement.billingNumber + '</b> und Datum <b>' + Vue.moment(this.selectedSettlement.invoiceDate).format('DD-MM-YYYY') + '</b>');
        })
    },

    onUpdateBillingState() {
      this.updateSettlementBillingState(this.selectedSettlement.billingNumber, this.selectedBillingState);
    },

    updateSettlementBillingState(billingNumber, state) {
      this.sending = true;
      SageService.updateSettlementBillingState(billingNumber, state)
        .then(() => {
          this.selectedSettlement.billingState = this.selectedBillingState;
          this.sending = false;
          this.$store.commit('successMsg', 'Der Status der Rechnung <b>' + billingNumber + '</b>&nbsp;wurde erfolgreich auf <b>' + this.labels.billingState[state] + '</b> gesetzt.');
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Aktualisieren der Rechnung <b>' + billingNumber + '</b> auf Status <b>' + this.labels.billingState[state] + '</b>');
          this.sending = false;
        });
    },

    onCancelSettlement() {
      this.cancelSettlement(this.getOrganizationId(this.selectedSettlement.customerNo), this.selectedSettlement.billingNumber);
    },

    cancelSettlement(organizationId, billingNumber) {
      this.sending = true;
      SageService.cancelSettlement(organizationId, billingNumber)
        .then(() => {
          this.sending = false;
          this.confirmCancelSettlement = false;
          this.$store.commit('successMsg', 'Die Rechnung <b>' + billingNumber + '</b>&nbsp;wurde erfolgreich storniert.');
          this.$emit("reload-settlements");
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Stornieren der Rechnung <b>' + billingNumber + '</b>');
          this.sending = false;
        });
    },

    onCreateSettlement() {
      this.createSettlement(this.getOrganizationId(this.selectedSettlement.customerNo), this.selectedSettlement.billingNumber);
    },

    createSettlement(organizationId, billingNumber) {
      this.sending = true;
      SageService.createSettlement(organizationId)
        .then(() => {
          this.sending = false;
          this.confirmCreateSettlement = false;
          this.$store.commit('successMsg', 'Die neue Rechnung für die alte Nr.<b>' + billingNumber + '</b>&nbsp;wurde erfolgreich erzeugt.');
          this.$emit("reload-settlements");
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der Rechnung mit alter Nr. <b>' + billingNumber + '</b>');
          this.sending = false;
        });
    },
  },

  computed: {
    isSage() {
      return this.$store.getters.sage;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    labels() {
      return this.$store.getters.labels
    },

    organization() {
      return this.$store.getters.organization
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

table {
  width: 100% !important;
}

tbody th, tbody tr, tbody td {
  padding: 3px 5px;
}

thead th, thead tr, thead td {
  padding: 3px 5px;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: transparent;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: normal;
  max-width: 400px;
}

.md-content {
 font-size: large;
}

.md-table-cell {
  padding: 0;
}

span b {
  color: cornflowerblue;
}
</style>