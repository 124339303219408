<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" v-if="organization" id="editOrganizationDialogId" :md-active.sync="showDialog" @md-closed="closeDialog">

      <md-dialog-title>
        <form novalidate @submit.prevent="validateOrganization">

          <div class="grid-x grid-padding-x">

            <div v-if="mode === 'update'" class="cell large-5 medium-5">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Organisation bearbeiten - {{organization.customerNo}}
            </div>
            <div v-else-if="mode === 'create'" class="cell large-5 medium-5">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp; Neue Organisation anlegen (Sage)
            </div>
            <div class="cell large-2 medium-2 small-12" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'create'" class="button success" type="submit" :disabled="sending">
                <i class="fi-thumbnails"></i>&nbsp;&nbsp;&nbsp;Organisation jetzt anlegen
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>
        </form>
      </md-dialog-title>

      <md-dialog-content>

        <md-tabs md-dynamic-height>
          <template slot="md-tab" slot-scope="{ tab }">
            <span class="md-tab-label" style="margin-top: 10px;">{{ tab.label }} <i class="badge" v-if="tab.data.badge">1</i></span>
            <md-tooltip>
              <div v-if="tab.data.badge">Einwilligung zur Datenverarbeitung für <b>{{tab.data.fullName}}</b> fehlt!</div>
            </md-tooltip>
          </template>

          <md-tab md-label="Stammdaten">
            <div class="grid-x grid-padding-x">
              <div class="cell large-6 medium-12 small-12">
                <md-field :class="getValidationClass('name')">
                  <label for="organization-name">Name des Trägers</label>
                  <md-input name="organization-name" id="organization-name" autocomplete="off"
                            v-model="organization.name" :disabled="sending"/>
                  <span class="md-error" v-if="!$v.organization.name.required">Name ist notwendig</span>
                  <span class="md-error" v-else-if="!$v.organization.name.minlength">Ungültiger Name</span>
                </md-field>
              </div>
              <div v-if="mode === 'create'" class="cell large-6 medium-12 small-12">
                <md-field :class="getValidationClass('customerNo')">
                  <label for="customerNo">Kunden-Nummer</label>
                <md-input name="customerNo" id="customerNo" autocomplete="off"
                            v-model="organization.customerNo" :disabled="sending"/>
                  <span class="md-error" v-if="!$v.organization.customerNo.required">Kunden-Nummer ist notwendig</span>
                </md-field>
              </div>
              <div class="cell large-6 medium-12 small-12">
              </div>
              <div v-if="organization.address && mode !== 'create'" class="cell">
                <AddressForm ref="organizationAddress" :address="organization.address"
                             :sending="sending"></AddressForm>
              </div>
              <div v-if="mode !== 'create'" class="cell large-6 medium-12 small-12">
                <md-field :class="getValidationClass('organizationType')">
                  <label>Organisationsform</label>
                  <md-select id="organization-type" v-model="organization.organizationType"
                             autocomplete="off"
                             :disabled="sending">
                    <md-option v-for="type of labels.organizationTypes" v-bind:value="type" :key="type">
                      {{labels.organizationType[type]}}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div v-if="mode !== 'create'" class="cell large-6 medium-12 small-12">
                <md-field>
                  <label for="registration-no">Registernummer</label>
                  <md-input name="registration-no" id="registration-no" autocomplete="off"
                            v-model="organization.associationRegistrationNumber" :disabled="sending"/>
                </md-field>
              </div>
              <div v-if="mode !== 'create'" class="cell large-6 medium-12 small-12">
                <md-field>
                  <label for="creditor-id">Gläubiger-Identifikationsnummer</label>
                  <md-input name="creditor-id" id="creditor-id" autocomplete="off" maxlength="35"
                            v-model="organization.creditorId" :disabled="sending"/>
                </md-field>
              </div>
              <div v-if="mode !== 'create'" class="cell large-6 medium-12 small-12">
                <md-field :class="getValidationClass('settlementEmail')">
                  <label for="settlement-email-id">Email für Rechnungsversand</label>
                  <md-input type="email" name="settlement-email-id" id="settlement-email-id" autocomplete="off"
                            v-model="organization.settlementEmail" :disabled="sending"/>
                  <span class="md-error" v-if="!$v.organization.settlementEmail.email">Ungültige Email-Adresse</span>
                </md-field>
              </div>
            </div>
          </md-tab>
          <md-tab v-if="organization.contact && mode !== 'create'" md-label="Kontakt">
            <ContactForm ref="organizationContact" :contact="organization.contact"
                         :sending="sending" :disableEmail="organization.testPhaseActive"></ContactForm>
          </md-tab>
          <md-tab md-label="Bankverbindung">
            <BankAccountForm ref="bankAccount" :bankAccount="organization.bankAccount"
                             :paymentMethod="''" :mandateReferenceDisabled="true"
                             :sending="sending"></BankAccountForm>
          </md-tab>
          <md-tab v-if="organization.responsiblePerson && mode !== 'create'" md-label="Verantwortung"
                  :md-template-data="{ badge: !organization.responsiblePerson.dsgvoConfirmationObtained, fullName: organization.responsiblePerson.fullName }">
            <PersonForm ref="responsiblePerson" :person="organization.responsiblePerson"
                        :sending="sending"></PersonForm>
            <AddressForm v-if="organization.responsiblePerson.address" ref="responsiblePersonAddress" :address="organization.responsiblePerson.address"
                         :sending="sending"></AddressForm>
            <ContactForm ref="responsiblePersonContact" :contact="organization.responsiblePerson.contact"
                         :sending="sending"></ContactForm>
          </md-tab>
        </md-tabs>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingDataDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-home"></i>&nbsp;&nbsp;&nbsp;Unvollständigen bzw. fehlerhaften
          Datensatz für Organisation bestätigen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll der unvollständige bzw. fehlerhafte Datensatz wirklich gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="doConfirmation" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, speichern</div>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="closeConfirmationDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeConfirmationDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>
</template>

<script>

  import jQuery from 'jquery';
  import PersonForm from '../forms/PersonForm';
  import AddressForm from '../forms/AddressForm';
  import ContactForm from "../forms/ContactForm";
  import BankAccountForm from '../forms/BankAccountForm';
  import SessionTime from '../animations/SessionTime';

  import OrganizationService from '../../services/OrganizationService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  import {validationMixin} from 'vuelidate'
  import {
    required,
    minLength, email,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'EditOrganizationDialog',
    mixins: [validationMixin],

    components: {
      AddressForm,
      ContactForm,
      PersonForm,
      BankAccountForm,
      SessionTime,
    },

    props: {
      organization: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    watch: {
      organization() {
        HttpErrorHandler.maintainDarkMode(this, 100);
      }
    },

    data: () => ({
      success: true,
      sending: false,
      confirmMissingDataDialog: false,
    }),

    validations: {
      organization: {
        name: {
          required,
          minLength: minLength(3)
        },
        customerNo: {
          required,
        },
        organizationType: {
        },
        settlementEmail: {
          email,
        },
      }
    },

    methods: {
      getValidationClass(fieldName) {
        let field = this.$v.organization[fieldName];
        if (!field) {
          field = this.$v.organization.contact[fieldName];
        }

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      createOrganization() {
        this.sending = true;
        this.saved = false;
        OrganizationService.createOrganization(this.organization)
          .then(response => {
            this.form = response.data;
            this.saved = true;
            this.sending = false;
            this.$emit("updateOrganizationSuccess", response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Anlegen der Organisation <b>' + this.organization.name + '</b>');
            this.sending = false;
          })
      },

      updateOrganization() {
        this.sending = true;
        this.saved = false;

        if (this.isSage) {
          OrganizationService.updateOrganizationForId(this.organization)
            .then(response => {
              this.form = response.data;
              this.saved = true;
              this.sending = false;
              this.$emit("updateOrganizationSuccess", response.data);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Aktualisieren der Daten für Träger <b>' + this.organization.name + '</b>');
              this.sending = false;
            })
        } else {
          OrganizationService.updateOrganization(this.organization)
            .then(response => {
              this.form = response.data;
              this.saved = true;
              this.sending = false;
              this.$emit("updateOrganizationSuccess", response.data);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Aktualisieren der Daten für Träger <b>' + this.organization.name + '</b>');
              this.sending = false;
            })
        }
      },

      validateOrganization() {
        this.$v.$touch();

        if (this.mode === 'create') {
          if (!this.$v.$invalid) {
            this.createOrganization();
          } else {
            setTimeout(() => this.confirmMissingData(), 250);
          }
        }

        if (this.mode === 'update') {
          this.$refs.organizationAddress.validateAddress();
          this.$refs.organizationContact.validateContact();
          this.$refs.bankAccount.validateBankAccount();

          this.$refs.responsiblePerson.validatePerson();
          this.$refs.responsiblePersonAddress.validateAddress();
          this.$refs.responsiblePersonContact.validateContact();

          if (this.$refs.organizationAddress.invalid) {
            jQuery('#editOrganizationDialogId').find('.md-tabs-navigation').children(':button.md-button').first().trigger('click');
          } else if (this.$refs.organizationContact.invalid) {
            jQuery('#editOrganizationDialogId').find('.md-tabs-navigation').children(':button.md-button').first().next().trigger('click');
          } else if (this.$refs.bankAccount.invalid) {
            jQuery('#editOrganizationDialogId').find('.md-tabs-navigation').children(':button.md-button').first().next().next().trigger('click');
          } else if (this.$refs.responsiblePerson.invalid || this.$refs.responsiblePersonAddress.invalid || this.$refs.responsiblePersonContact.invalid) {
            jQuery('#editOrganizationDialogId').find('.md-tabs-navigation').children(':button.md-button').first().next().next().next().trigger('click');
          }

          if (!(this.$v.$invalid ||
            this.$refs.organizationAddress.invalid ||
            this.$refs.organizationContact.invalid ||
            this.$refs.bankAccount.invalid ||
            this.$refs.responsiblePerson.invalid ||
            this.$refs.responsiblePersonAddress.invalid ||
            this.$refs.responsiblePersonContact.invalid)) {

            this.updateOrganization();
          } else {
            setTimeout(() => this.confirmMissingData(), 250);
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditOrganizationDialog");
        this.$v.$reset();
      },

      doConfirmation() {
        this.confirmMissingDataDialog = false;
        jQuery('#editOrganizationDialogId').attr("style", "display:normal");
        if (this.mode === 'create') {
          this.createOrganization();
        }
        if (this.mode === 'update') {
          this.updateOrganization();
        }
      },
      
      confirmMissingData() {
        jQuery('#editOrganizationDialogId').attr("style", "display:none");
        this.confirmMissingDataDialog = true;
      },

      closeConfirmationDialog() {
        this.confirmMissingDataDialog = false;
        jQuery('#editOrganizationDialogId').attr("style", "display:normal");
      },
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isSage() {
        return this.$store.getters.sage
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style scoped>

  .md-dialog /deep/.md-dialog-container {
    width: 1200px;
    max-width: 95%;
    max-height: 95%;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .badge {
    width: 19px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
  }
</style>