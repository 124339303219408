import HttpInterceptor from "./HttpInterceptor";
import Vue from 'vue';

export default {

  name: 'BinaryService',

  binaryCache: {},

  getImage(id, ignoreCache) {
    if (this.binaryCache[id]) {
      const elapsedSeconds = Math.round((Vue.moment().valueOf() - this.binaryCache[id].lastLoad.valueOf()) / 1000);
      if ((elapsedSeconds > process.env.VUE_APP_BINARY_CACHING_SECONDS) || ignoreCache) {
        return null;
      }
      return this.binaryCache[id];
    }
    return null;
  },

  loadImage(id) {
    return HttpInterceptor.httpClient().get('binary/image/' + id, {responseType: 'blob'});
  },

  uploadChildImage(childId, file, blob) {
    const formData = new FormData();
    formData.append("file", blob, file.upload.filename);

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token'),
      },
    };
    return HttpInterceptor.httpClient().post('binary/image/upload/child/' + childId, formData, config);
  },

  uploadEmployeeImage(employeeId, file, blob) {
    const formData = new FormData();
    formData.append("file", blob, file.upload.filename);

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token'),
      },
    };
    return HttpInterceptor.httpClient().post('binary/image/upload/employee/' + employeeId, formData, config);
  },

  storeImage(id, image) {
    image.lastLoad = Vue.moment();
    this.binaryCache[id] = image;
  },

  deleteLetterPaper(facilityReferenceNo, page) {
    return HttpInterceptor.httpClient().delete('binary/letter/delete/' + facilityReferenceNo + '/' + page);
  },

  deleteParentContractDocument(childId, refNr) {
    return HttpInterceptor.httpClient().delete('binary/parentcontract/delete/child/' + childId + '/contract/' + refNr);
  },

  deleteParentContractTemplateDocument(childId, refNr) {
    return HttpInterceptor.httpClient().delete('binary/parentcontract/delete/child/' + childId + '/contract/' + refNr + '?template=true');
  },

  deleteEmployeeContractDocument(employeeId) {
    return HttpInterceptor.httpClient().delete('binary/employeecontract/delete/employee/' + employeeId);
  },

  downloadParentContractDocument(documentId) {
    return HttpInterceptor.httpClient().get('binary/parentcontract/' + documentId, {
      responseType: 'blob',
      'Content-Type': 'application/pdf'
    });
  },

  downloadEmployeeContractDocument(documentId) {
    return HttpInterceptor.httpClient().get('binary/employeecontract/' + documentId, {
      responseType: 'blob',
      'Content-Type': 'application/pdf'
    });
  },

  getInvoicePdf(key) {
    return HttpInterceptor.httpClient().get('binary/invoice/' + key, {responseType: 'blob'});
  },

  getContractPdf(key) {
    return HttpInterceptor.httpClient().get('binary/contractdata/' + key, {responseType: 'blob'});
  },

  getCancellationPdf(key) {
    return HttpInterceptor.httpNoAuthBinaryClient().get('binary/cancellation/' + key, {responseType: 'blob'});
  },
}
