<template>
  <div v-if="organization">
    <div v-if="raw">
    </div>
    <div v-else>
      <md-card-header>
        <div class="md-title">{{ organization.name }}</div>
        <div v-if="organization.responsiblePerson" class="md-subhead">
          {{ labels.salutation[organization.responsiblePerson.salutation] }}
          {{ organization.responsiblePerson.title }}
          {{ organization.responsiblePerson.firstname }} {{ organization.responsiblePerson.lastname }}
        </div>
      </md-card-header>
      <md-card-content>
        <div class="grid-x grid-padding-y">
          <div class="cell large-6">
            <h5>Adresse</h5>
            <AddressView v-if="organization.address" :address="organization.address"></AddressView>
          </div>
          <div class="cell large-6">
            <h5>Kontakt</h5>
            <ContactView v-if="organization.contact" :contact="organization.contact"/>
          </div>
        </div>
        <div class="grid-x grid-padding-y">
          <div class="cell large-6">
            <h5 v-if="organization.responsiblePerson && organization.responsiblePerson.salutation === 'MS'">
              Trägerverantwortliche</h5>
            <h5 v-else>Trägerverantwortlicher</h5>
            <div v-if="organization.facilities && organization.facilities.length > 0">
              <PersonView v-if="organization.responsiblePerson"
                          :facilityRef="organization.facilities[0].referenceNumber"
                          :person="organization.responsiblePerson" :birthDateDisabled="false"/>
            </div>
            <div v-else>
              <PersonView v-if="organization.responsiblePerson" :person="organization.responsiblePerson"
                          :birthDateDisabled="false"/>
            </div>
          </div>
          <div class="cell large-6">
            <h5>Organisation</h5>

            <table class="hover">
              <tbody>
              <tr>
                <td class="td-width">Organisationsform</td>
                <td><p><span v-if="organization.organizationType">{{ labels.organizationType[organization.organizationType] }}</span></p></td>
              </tr>
              <tr>
                <td class="td-width">Registernummer</td>
                <td><p><span v-if="organization.associationRegistrationNumber">{{ organization.associationRegistrationNumber }}</span></p></td>
              </tr>
              <tr>
                <td class="td-width">Gläubiger-Identifikationsnr.</td>
                <td><p><span v-if="organization.creditorId">{{ organization.creditorId }}</span></p></td>
              </tr>
              <tr>
                <td class="td-width">Email für Rechnungsversand</td>
                <td><p><span v-if="organization.settlementEmail">{{ organization.settlementEmail }}</span></p></td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </md-card-content>
    </div>
  </div>
</template>

<script>
import AddressView from '../../components/views/AddressView';
import ContactView from "../../components/views/ContactView";
import PersonView from "../../components/views/PersonView";

export default {
  name: 'OrganizationView',
  components: {
    AddressView,
    ContactView,
    PersonView,
  },

  props: {
    organization: {
      required: true
    },
    raw: {
      required: false
    },
  },

  computed: {
    labels() {
      return this.$store.getters.labels
    },
  }
}
</script>

<style lang="scss" scoped>

p {
  margin: 0;
}

table {
  border-collapse: unset;
}

tbody th, tbody td {
  padding: 0;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: transparent;
}

.td-width {
  width: 210px;
}

span {
  color: cornflowerblue;
}

</style>