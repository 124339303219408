<template>
  <div class="grid-x grid-padding-x grid-padding-y">
    <div v-if="contract" class="cell">
      <md-card id="contract-view-id" :class="cardClasses">
        <div>
          <md-card-header>
            <div class="grid-x grid-padding-x">
              <div :class="layoutClasses">
                <p class="md-title md-subhead" style="font-size: xx-large">Vertragsübersicht</p><br>
                <p class="md-title">Vertrag-Nr : <span>{{ contract.contractNumber }}</span>
                  <span v-if="contract.cancellationRequested && !isDeleted">
                    <md-icon style="color: darkorange; font-size: 2rem !important; padding-bottom: 5px;">close</md-icon>
                    <md-tooltip>Vertrag gekündigt, Service endet zum {{
                        contract.end | moment("DD.MM.YYYY")
                      }}</md-tooltip>
                  </span>
                </p>
                <p class="md-subhead">Kunden-Nr : <b>{{ contract.customerNo }}</b></p>
              </div>
              <div :class="layoutClasses">
                <span v-if="!isSage"><br><br></span>
                <div v-if="contract.cancellationRequested && !isDeleted" class="grid-x grid-padding-x">
                  <div class="cell large-6 medium-6 small-6">
                    <div class="button success" style="margin-bottom: 5px;" @click="onUndoCancelContract">
                      <md-icon class="order-icon">undo</md-icon>
                      Kündigung zurückziehen
                    </div>
                  </div>
                  <div class="cell large-6 medium-6 small-6">
                    <p class="md-title" style="color: red">gekündigt am
                      {{ contract.cancellationRequested | moment("DD.MM.YYYY") }}</p>
                    <md-tooltip>Vertrag gekündigt, Service endet zum {{
                        contract.end | moment("DD.MM.YYYY")
                      }}
                    </md-tooltip>
                  </div>
                </div>
                <div v-if="isDeleted" class="grid-x grid-padding-x">
                  <div class="cell large-6 medium-6 small-6">
                    <p class="md-title" style="color: cadetblue">Der Vertrag ist beendet.</p>
                  </div>
                </div>
              </div>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="grid-x grid-padding-x">
              <div :class="layoutClasses">
                <br>
                <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                    style="color: lightseagreen;">Vertrag</span></p>
                <table class="hover">
                  <tbody>
                  <tr>
                    <td>Vertragspartner :</td>
                    <td>
                      <p><span>{{ organization.name }}</span>
                        <md-tooltip>Träger</md-tooltip>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Kontaktperson :</td>
                    <td>
                      <p><span
                          v-if="organization.responsiblePerson">{{
                          labels.salutation[organization.responsiblePerson.salutation]
                        }}
                      {{ organization.responsiblePerson.title }}
                      {{ organization.responsiblePerson.firstname }} {{
                          organization.responsiblePerson.lastname
                        }}</span>
                        <md-tooltip>verantwortliche Person</md-tooltip>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align: top;">Anschrift :</td>
                    <td>
                      <p><span><AddressView v-if="organization.address"
                                            :address="organization.address"></AddressView></span>
                        <md-tooltip>Rechnungsanschrift</md-tooltip>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Vertragsbeginn :</td>
                    <td><p><span>{{ contract.start | moment("DD. MMMM YYYY") }}</span></p></td>
                  </tr>
                  <tr v-if="contract.cancellationRequested">
                    <td>gekündigt am :</td>
                    <td><p><span>{{ contract.cancellationRequested | moment("DD. MMMM YYYY") }}</span></p></td>
                  </tr>
                  <tr v-if="contract.cancellationRequested">
                    <td>Vertragsende :</td>
                    <td><p><span>{{ contract.end | moment("DD. MMMM YYYY") }}</span></p></td>
                  </tr>
                  <tr v-if="contract.payingCustomer">
                    <td><b>maximale Anzahl Kinder : </b></td>
                    <td>
                      <span
                          style="color: cornflowerblue; padding-right: 1rem;"><b>{{ contract.maxNumberOfChildren }}</b></span>
                      <button v-if="!contract.cancellationRequested && !isSage"
                              class="button success-transparent order-button" @click="onChangeNumberOfChildren">
                        <md-icon class="order-icon">add</md-icon>
                        /
                        <md-icon class="order-icon">remove</md-icon>&nbsp;&nbsp;&nbsp;Anpassen
                        <md-tooltip>maximale Anzahl Kinder jetzt anpassen</md-tooltip>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="contract.payingCustomer">
                    <td>Partner-Mitgliedschaft :</td>
                    <td><p><span>{{ booleanText(contract.partnerMember) }}</span></p></td>
                  </tr>
                  </tbody>
                </table>
                <br>
              </div>
              <div :class="layoutClasses">
                <br>
                <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                    style="color: lightseagreen;">Module</span></p>
                <table class="hover">
                  <thead>
                  <tr>
                    <td>Name</td>
                    <td>gebucht</td>
                    <td>anteilig</td>
                    <td>{{ labels.paymentInterval[contract.interval] }}</td>
                    <td v-if="!isSage"></td>
                  </tr>
                  </thead>
                  <tbody v-if="modules">
                  <tr v-for="module of modules" :key="module.id" v-if="module.valid && !module.standalone">
                    <td>{{ module.label }}
                      <md-tooltip>{{ module.description }}</md-tooltip>
                    </td>
                    <td v-if="isContractedModule(module)">
                      <md-icon style="color: seagreen">check</md-icon>
                      <md-tooltip>gebucht</md-tooltip>
                    </td>
                    <td v-if="!isContractedModule(module) && isCancelledModule(module)">
                      <md-icon style="color: darkorange">close</md-icon>
                      <md-tooltip>abbestellt</md-tooltip>
                    </td>
                    <td v-if="!isContractedModule(module) && !isCancelledModule(module)">
                      <md-icon style="color: lightgray">remove</md-icon>
                      <md-tooltip>nicht gebucht</md-tooltip>
                    </td>
                    <td>{{ getRestPrice(module) }}
                      <md-tooltip v-if="getRestPrice(module)" v-html="getRestPriceLabel(module)"></md-tooltip>
                    </td>
                    <td>{{ getFollowUpPrice(module) }}
                      <md-tooltip v-if="getFollowUpPrice(module)" v-html="getFollowUpPriceLabel(module)"></md-tooltip>
                    </td>
                    <td v-if="contract.cancellationRequested"></td>
                    <td v-else-if="!isSage">
                      <button v-if="!isContractedModule(module) && !isCancelledModule(module)" :id="module.id"
                              class="button success-transparent order-button" @click="onAddModule(module)">
                        <md-icon class="order-icon">add</md-icon>
                        Bestellen
                        <md-tooltip>Modul <b>{{ module.label }}</b> jetzt bestellen</md-tooltip>
                      </button>
                      <button v-if="isCancelledModule(module)" class="button success-transparent order-button"
                              @click="onUndoRemoveModule(module)">
                        <md-icon class="order-icon">undo</md-icon>
                        Rückgängig
                        <md-tooltip>Abbestellung für Modul <b>{{ module.label }}</b> jetzt rückgängig machen.
                        </md-tooltip>
                      </button>
                      <button v-if="isContractedModule(module) && !isCancelledModule(module) && !module.base"
                              class="button alert-transparent order-button" @click="onRemoveModule(module)">
                        <md-icon class="order-icon">remove</md-icon>
                        Abbestellen
                        <md-tooltip>Modul <b>{{ module.label }}</b> abbestellen</md-tooltip>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <br>
              </div>
              <div v-if="contract.payingCustomer" :class="layoutClasses">
                <br>
                <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                    style="color: lightseagreen;">Kosten</span></p>
                <table class="hover">
                  <tbody v-if="contract.settlements.length > 0">
                  <tr>
                    <td>Zahlungsintervall :</td>
                    <td><p><span>{{ labels.paymentInterval[contract.interval] }}</span></p></td>
                  </tr>
                  <tr>
                    <td>Preis {{ labels.paymentInterval[contract.interval] }} :</td>
                    <td><p>
                      <span>{{
                          (contract.viewValues.gesamtKostenBrutto + contract.viewValues.gesamtNachlassBrutto) / 100 | currency
                        }}</span>
                    </p></td>
                  </tr>
                  <tr>
                    <td>Nachlass pro Zahlungsintervall ({{ labels.paymentInterval[contract.interval] }}) :</td>
                    <td><p><span>{{ contract.viewValues.gesamtNachlassBrutto / 100 | currency }}</span></p></td>
                  </tr>
                  <tr>
                    <td><b>Ihr Preis aktuell {{ labels.paymentInterval[contract.interval] }} : </b></td>
                    <td><p><span><b>{{ contract.viewValues.gesamtKostenBrutto / 100 | currency }}</b></span></p></td>
                  </tr>
                  <tr v-if="contract.viewValues.gesamtKostenBrutto !== contract.viewValues.gesamtKostenBruttoFolgeIntervall">
                    <td><b>Ihr Preis ab dem folgenden Zahlungsintervall {{ labels.paymentInterval[contract.interval] }}
                      : </b></td>
                    <td><p>
                      <span><b>{{ contract.viewValues.gesamtKostenBruttoFolgeIntervall / 100 | currency }}</b></span>
                    </p></td>
                  </tr>
                  <tr>
                    <td>letzte Zahlung am :</td>
                    <td><p><span>{{ contract.viewValues.lastPaymentDate | moment("DD. MMMM YYYY") }}</span></p></td>
                  </tr>
                  <tr>
                    <td>nächste Zahlung fällig am :</td>
                    <td><p><span>{{ contract.viewValues.nextPaymentDate | moment("DD. MMMM YYYY") }}</span></p></td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <p class="md-subhead" style="font-size: x-large; padding: 1rem;"><span>Ihre Kostenübersicht ist in Kürze verfügbar.</span>
                  </p>
                  </tbody>
                </table>
                <br>
              </div>
              <div v-if="contract.payingCustomer" :class="layoutClasses">
                <br>
                <p class="md-subhead" style="font-size: x-large; padding-bottom: 10px;"><span
                    style="color: lightseagreen;">Zahlung</span></p>
                <table class="hover">
                  <tbody v-if="contract.method === 'SEPA' && contract.account">
                  <tr>
                    <td>Zahlungsweise :</td>
                    <td><p><span>{{ labels.paymentMethod[contract.method] }}</span></p></td>
                  </tr>
                  <tr>
                    <td>Kontoinhaber :</td>
                    <td><p><span>{{ contract.account.accountOwner }}</span></p></td>
                  </tr>
                  <tr>
                    <td>Name der Bank :</td>
                    <td><p><span>{{ contract.account.bankname }}</span></p></td>
                  </tr>
                  <tr>
                    <td>IBAN :</td>
                    <td><p><span>{{ contract.account.ibanCompact }}</span></p></td>
                  </tr>
                  <tr>
                    <td>BIC :</td>
                    <td><p><span>{{ contract.account.bic }}</span></p></td>
                  </tr>
                  <tr>
                    <td>Referenz-Mandat :</td>
                    <td><p><span>{{ contract.account.mandateReference }}</span></p></td>
                  </tr>
                  <tr>
                    <td>Datum der Mandatserteilung :</td>
                    <td><p><span>{{ contract.account.dateOfMandateGranting | moment("DD. MMMM YYYY") }}</span></p></td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td>Zahlungsweise :</td>
                    <td><p><span>{{ labels.paymentMethod[contract.method] }}</span></p></td>
                  </tr>
                  </tbody>
                </table>
                <br>
              </div>
            </div>
          </md-card-content>

          <md-card-actions v-if="!isSage && !isDeleted" style="padding-right: 1rem">
            <div v-if="contract && contract.contractDocumentsKey" @click="confirmDownloadPerformanceAgreementPdf = true"
                 class="button warn" style="margin-right: 1rem">
              <i class="fi-page-multiple"></i>&nbsp;&nbsp;&nbsp;Leistungsvereinbarung
              <md-tooltip>
                Leistungsvereinbarung zwischen der <b>ai42 UG (haftungsbeschränkt)</b> und
                <b>{{ organization.name }}</b> als PDF herunterladen.
              </md-tooltip>
            </div>
            <div v-if="contract.cancellationRequested" class="button success" @click="onUndoCancelContract" :disabled="sending || !isAdmin || !canWrite">
              <md-icon class="order-icon">undo</md-icon>
              Kündigung Vertrag zurückziehen
            </div>
            <div v-else class="button secondary" @click="onCancelContract" :disabled="sending || !isAdmin || !canWrite">Vertrag kündigen</div>
          </md-card-actions>
        </div>
      </md-card>
    </div>
    <div v-else class="cell">
      <p class="md-title md-subhead">Keine Vertragsdaten verfügbar</p><br>
    </div>

    <OrderModuleDialog :show-dialog="showOrderModuleDialog" :mode="orderMode" :module="selectedModule"
                       :contract="contract" :priceLabels="priceLabels"
                       @closeOrderModuleDialog="onCloseOrderModuleDialog"
                       @addModuleSuccess="onAddModuleSuccess"
                       @removeModuleSuccess="onRemoveModuleSuccess"
                       @undoRemoveModuleSuccess="onUndoRemoveModuleSuccess"
                       @changeNumberOfChildrenSuccess="onChangeNumberOfChildrenSuccess"
                       @cancelContractSuccess="onCancelContractSuccess"
                       @undoCancelContractSuccess="onUndoCancelContractSuccess"></OrderModuleDialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmDownloadPerformanceAgreementPdf">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-page-multiple"></i>&nbsp;&nbsp;&nbsp;Leistungsvereinbarung
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Soll die individuelle Leistungsvereinbarung zwischen dem Lizenzgeber<br><br>
            <span class="title">ai42 UG (haftungsbeschränkt)</span><br> und<br>
            <span class="title">{{ organization.name }}</span><br><br>
            als PDF heruntergeladen werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button :disabled="sending" @click="onDownloadPerformanceAgreementPdf" class="button success"><i
              class="fi-download"></i>&nbsp;&nbsp;&nbsp;Leistungsvereinbarung herunterladen
          </button>
          <button :disabled="sending" @click="confirmDownloadPerformanceAgreementPdf = false"
                  class="button alert"
                  style="margin-left: 1rem;"
                  type="button">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
          </button>
        </div>
        <button @click="confirmDownloadPerformanceAgreementPdf = false" class="close-button" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>
</template>

<script>
import AddressView from '../AddressView';
import Vue from 'vue';
import OrderModuleDialog from "../../dialogs/OrderModuleDialog";
import BinaryService from "../../../services/BinaryService";
import HttpErrorHandler from "../../../services/HttpErrorHandler";

export default {
  name: 'ContractView',

  components: {
    OrderModuleDialog,
    AddressView,
  },

  props: {
    contract: {
      required: true
    },
    organization: {
      required: true
    },
    layoutClasses: {
      required: true,
    },
    cardClasses: {
      required: false,
    },
  },

  data: () => ({
    showOrderModuleDialog: false,
    orderMode: 'add',
    selectedModule: null,
    priceLabels: [],

    sending: false,
    confirmDownloadPerformanceAgreementPdf: false,

    performanceAgreementPdf: {
      url: null,
      link: null,
      blob: null,
    },
  }),

  methods: {

    cleanPDF() {
      if (this.performanceAgreementPdf.url) {
        this.performanceAgreementPdf.url = window.URL.revokeObjectURL(this.performanceAgreementPdf.url);
        this.performanceAgreementPdf.blob = null;
      }
      this.performanceAgreementPdf.url = null;
    },

    onDownloadPerformanceAgreementPdf() {
      this.cleanPDF();
      this.downloadPerformanceAgreementPdf(this.contract.contractDocumentsKey, this.performanceAgreementPdf);
    },

    downloadPerformanceAgreementPdf(key, pdf) {
      this.sending = true;
      BinaryService.getContractPdf(key)
        .then(response => {
          this.sending = false;
          setTimeout(() => {
            pdf.blob = new Blob([response.data, 'utf-8'], {type: "application/pdf"});
            pdf.url = window.URL.createObjectURL(pdf.blob);

            if (pdf.link) {
              document.body.removeChild(pdf.link);
            }
            pdf.link = document.createElement('a');
            const id = this.organization.name + ' (' + this.organization.customerNo + ') Leistungsvereinbarung zwischen ai42 UG (haftungsbeschränkt) und ' + this.organization.name;
            pdf.link.href = pdf.url;
            pdf.link.setAttribute('download', id + '.pdf');
            pdf.link.setAttribute('id', id);
            document.body.appendChild(pdf.link);
            pdf.link.click();

            this.confirmDownloadPerformanceAgreementPdf = false;
          }, 50);
        })
        .catch(e => {
          this.sending = false;
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden des PDF für die Leistungsvereinbarung');
        })
    },

    booleanText(value) {
      return value ? 'ja' : 'nein';
    },

    isContractedModule(module) {
      for (let mod of this.contract.modules) {
        if (mod.type === module.type) {
          return !this.contract.modulesToRemove.includes(mod.type);
        }
      }
      return false;
    },

    isCancelledModule(module) {
      for (let mod of this.contract.modules) {
        if (mod.type === module.type) {
          return this.contract.modulesToRemove.includes(mod.type);
        }
      }
      return false;
    },

    getFollowUpPrice(module) {
      if (module && this.contract.viewValues.moduleValues[module.type] && !this.isDeleted) {
        return Vue.filter('currency')(this.contract.viewValues.moduleValues[module.type].folgepreisBrutto / 100);
      }
      return '';
    },

    getFollowUpPriceLabel(module) {
      if (module && this.getFollowUpPrice(module)) {
        return 'regelmäßig in den darauf folgenden Zahlungsintervallen (' + this.labels.paymentInterval[this.contract.interval] + ') : <b>' + this.getFollowUpPrice(module) + '</b> ';
      }
      return '';
    },

    getRestPrice(module) {
      if (module && this.contract.viewValues.moduleValues[module.type] && !this.isDeleted) {
        return Vue.filter('currency')(this.contract.viewValues.moduleValues[module.type].restzahlungBrutto / 100);
      }
      return '';
    },

    getRestPriceLabel(module) {
      if (module && this.getRestPrice(module)) {
        return 'einmalige anteilige Restzahlung für das laufende Zahlungsintervall (' + this.labels.paymentInterval[this.contract.interval] + ') : <b>' + this.getRestPrice(module) + '</b>';
      }
      return '';
    },

    onAddModule(module) {
      this.selectedModule = module;
      this.orderMode = 'add';
      this.priceLabels = [];
      if (this.getRestPrice(module)) {
        this.priceLabels.push(this.getRestPriceLabel(module));
      }
      if (this.getFollowUpPriceLabel(module)) {
        this.priceLabels.push(this.getFollowUpPriceLabel(module));
      }
      this.showOrderModuleDialog = true;
    },

    onRemoveModule(module) {
      this.selectedModule = module;
      this.orderMode = 'remove';
      this.priceLabels = [];
      this.showOrderModuleDialog = true;
    },

    onUndoRemoveModule(module) {
      this.selectedModule = module;
      this.orderMode = 'undo_remove';
      this.priceLabels = [];
      this.showOrderModuleDialog = true;
    },

    onAddModuleSuccess() {
      this.showOrderModuleDialog = false;
      this.$emit("updateOrganizationContract");
    },

    onRemoveModuleSuccess() {
      this.showOrderModuleDialog = false;
      this.$emit("updateOrganizationContract");
    },

    onUndoRemoveModuleSuccess() {
      this.showOrderModuleDialog = false;
      this.$emit("updateOrganizationContract");
    },

    onChangeNumberOfChildren() {
      this.selectedModule = null;
      this.showOrderModuleDialog = true;
      this.orderMode = 'change_max_children';
    },

    onChangeNumberOfChildrenSuccess() {
      this.showOrderModuleDialog = false;
      this.$emit("updateOrganizationContract");
    },

    onCloseOrderModuleDialog() {
      this.showOrderModuleDialog = false;
    },

    getBaseModule() {
      if (this.contract && this.contract.modules) {
        for (let module of this.contract.modules) {
          if (module.base) {
            return module;
          }
        }
      }
      return null;
    },

    onCancelContract() {
      this.selectedModule = this.getBaseModule();
      this.selectedModule = null;
      this.showOrderModuleDialog = true;
      this.orderMode = 'cancel';
    },

    onUndoCancelContract() {
      this.selectedModule = this.getBaseModule();
      this.selectedModule = null;
      this.showOrderModuleDialog = true;
      this.orderMode = 'undo_cancel';
    },

    onCancelContractSuccess() {
      this.showOrderModuleDialog = false;
      this.$emit("updateOrganizationContract");
    },

    onUndoCancelContractSuccess() {
      this.showOrderModuleDialog = false;
      this.$emit("updateOrganizationContract");
    },
  },

  computed: {
    isSage() {
      return this.$store.getters.sage;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    canWrite() {
      return this.$store.getters.canWrite;
    },

    isDeleted() {
      return this.$store.getters.deleted;
    },

    labels() {
      return this.$store.getters.labels
    },

    modules() {
      return this.$store.getters.modules
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

p span {
  color: cornflowerblue;
}

table {
  width: 100% !important;
}

tbody th, tbody tr, tbody td {
  padding: 3px 5px;
}

thead th, thead tr, thead td {
  padding: 3px 5px;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: transparent;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: normal;
  max-width: 400px;
}

.order-button {
  margin: 0;
  padding: 0.2em 1em;
}

.title {
  color: chocolate;
  font-weight: bold;
  font-size: larger;
}

</style>