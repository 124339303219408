<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDialog" @md-closed="closeDialog">

      <md-dialog-title>
        <div class="grid-x grid-padding-x">
          <div v-if="mode === 'add'" class="cell large-6 medium-7 small-8">
            <md-icon style="color: cornflowerblue">add</md-icon>&nbsp;&nbsp;&nbsp;Neues Modul bestellen
          </div>
          <div v-if="mode === 'remove'" class="cell large-6 medium-7 small-8">
            <md-icon style="color: cornflowerblue">remove</md-icon>&nbsp;&nbsp;&nbsp;Bestehendes Modul abbestellen
          </div>
          <div v-if="mode === 'undo_remove'" class="cell large-6 medium-7 small-8">
            <md-icon style="color: cornflowerblue">undo</md-icon>&nbsp;&nbsp;&nbsp;Abbestellung Modul rückgängig machen
          </div>
          <div v-if="mode === 'cancel'" class="cell large-6 medium-7 small-8">
            <md-icon style="color: cornflowerblue">close</md-icon>&nbsp;&nbsp;&nbsp;Vertrag kündigen
          </div>
          <div v-if="mode === 'undo_cancel'" class="cell large-6 medium-7 small-8">
            <md-icon style="color: cornflowerblue">undo</md-icon>&nbsp;&nbsp;&nbsp;Kündigung Vertrag zurückziehen
          </div>
          <div v-if="mode === 'change_max_children'" class="cell large-6 medium-7 small-8">
            <md-icon style="color: cornflowerblue">add</md-icon> / <md-icon style="color: cornflowerblue">remove</md-icon>&nbsp;&nbsp;&nbsp;Anzahl maximaler Kinder anpassen
          </div>
          <div class="cell large-2 medium-3 small-3 text-center" style="margin-top: -12px;">
            <SessionTime></SessionTime>
          </div>
          <div class="cell large-4 medium-2 small-1"></div>
        </div>
        <md-progress-bar md-mode="indeterminate" v-if="sending"/>
      </md-dialog-title>

      <md-dialog-content>
        <div class="grid-x">
          <div class="cell large-4 medium-6 text-center">
            <md-card v-if="module" md-with-hover class="text-left">
              <div :class="getSelectedClass()">
                <md-card-header>
                  <md-card-media>
                    <img src="../../assets/icons/modules.png" style="width: 30px">
                  </md-card-media>

                  <md-card-header-text>
                    <div class="md-title" style="font-size: 15px;">{{module.label}}</div>
                    <div v-if="module.type === 'BASE' || module.type === 'DOCUMENTATION_STANDALONE'">Grundmodul</div>
                    <div v-else>Zusatzmodul</div>
                  </md-card-header-text>
                </md-card-header>

                <md-card-content style="min-height: 160px; max-height: 160px;">
                  <hr style="margin-top: 0">
                  <md-content class="md-scrollbar">
                    <div class="md-subhead">{{module.description}}</div>
                  </md-content>
                </md-card-content>

                <md-card-actions :class="getSelectedClass()">
                  <div v-if="mode === 'add'" class="text-center">
                    <h5 style="color: seagreen;">ausgewählt&nbsp;<md-icon>done</md-icon></h5>
                  </div>
                  <div v-else>
                    <h4><md-icon style="color: black">add_shopping_cart</md-icon></h4>
                  </div>
                </md-card-actions>
              </div>
            </md-card>
            <div v-if="module && mode === 'add'" class="md-result-textarea text-justify" style="padding: 2rem;">
              Es freut uns, dass Sie unser Modul <b>{{module.label}}</b> nutzen möchten.
              Folgende Kosten werden mit dieser Bestellung fällig.
            </div>
            <div v-if="module && mode === 'remove'" class="md-result-textarea text-justify" style="padding: 2rem;">
              Es tut uns sehr leid, dass Sie unser Modul <b>{{module.label}}</b> nicht mehr nutzen möchten.
            </div>
            <div v-if="module && mode === 'undo_remove'" class="md-result-textarea text-justify" style="padding: 2rem;">
              Es freut uns, dass Sie es sich nochmal überlegt haben und unser Modul <b>{{module.label}}</b> weiter nutzen möchten.
            </div>
            <div v-if="mode === 'cancel'" class="md-result-textarea text-justify" style="padding: 2rem;">
              Es tut uns sehr leid, dass Sie unsere <b>Software für Soziale Einrichtungen</b> nicht mehr nutzen möchten.
            </div>
            <div v-if="mode === 'undo_cancel'" class="md-result-textarea text-justify" style="padding: 2rem;">
              Es freut uns, dass Sie es sich nochmal überlegt haben und unsere <b>Software für Soziale Einrichtungen</b> weiter nutzen möchten.
            </div>
            <div v-if="contract && mode === 'change_max_children'" class="md-result-textarea text-justify" style="padding: 2rem;">
              Es freut uns, dass Sie ihren Bedarf zur Verwaltung der Kinder anpassen möchten. Derzeit können Sie maximal <b>{{contract.maxNumberOfChildren}}</b> Kinder verwalten.
              Wählen Sie Ihren neuen Bedarf und bestätigen Sie Ihre Eingabe.
            </div>
          </div>
          <div v-if="module && mode === 'add'" class="cell large-8 medium-6">
            <md-card style="min-width: 95%">
              <md-card-header>
                <div class="md-title">Kosten</div>
                <div class="md-subhead md-result-textarea"><b>{{module.label}}</b></div>
              </md-card-header>
              <md-card-content v-if="priceLabels && priceLabels.length > 0">
                <div v-for="price of priceLabels" :key="price"><md-icon>check</md-icon> <span v-html="price" class="md-result-textarea"></span></div>
              </md-card-content>
              <md-card-content v-else>
                <h5>Ihre Auswahl beinhaltet <b style="color: seagreen;">kostenfreie</b> Leistungen.</h5>
              </md-card-content>
            </md-card>
            <br><br>
            <div class="cell" style="width: 95%; padding-left: 1rem">
              <div class="text-justify md-result-textarea">
                Im nächsten Schritt wird der bestehende <b>Vertrag Nr. {{contract.contractNumber}}</b> zwischen dem Träger
                <b>{{organization.name}}</b> und der <b>ai42 UG (haftungsbeschränkt)</b> mit <b>sofortiger Wirksamkeit</b> angepasst.
                Der neue Service beinhaltet zu dem bestehenden Vertrag angepasste Leistungsmerkmale zu der regelmäßig
                vereinbarten Gebühr und wird gemäß unseren AGB durchgeführt.
              </div>
              <br>
              <md-checkbox class="md-primary" v-model="agbConfirmed">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Ich habe die <a href="https://ai42.de/unternehmen/agb" target="_blank"><b>AGB</b></a> gelesen und bin damit einverstanden.
                <md-tooltip>Bitte lesen Sie unsere allgemeinen Geschäftsbedingungen (AGB) sorgfältig durch und stimmen Sie diesen zu.</md-tooltip>
              </span>
              <br>
              <md-checkbox class="md-primary" v-model="dsgvoConfirmed">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Die <a href="https://ai42.de/unternehmen/datenschutz" target="_blank"><b>Datenschutzerklärung</b></a> habe ich zur Kenntnis genommen.
                <md-tooltip>Bitte lesen Sie unsere Datenschutzerklärung sorgfältig durch und bestätigen Sie die Kenntisnahme.</md-tooltip>
              </span>
            </div>
            <br><br>
            <div v-if="priceLabels && priceLabels.length > 0" class="cell text-center">
              <button class="button warn" type="submit" :disabled="sending || !agbConfirmed || !dsgvoConfirmed" @click="validateOrder">
                <i class="fi-plus"></i>&nbsp;&nbsp;&nbsp;Modul jetzt kostenpflichtig bestellen
              </button>
              <md-tooltip><div v-if="!agbConfirmed || !dsgvoConfirmed">Bitte stimmen Sie den AGB zu und nehmen Sie die Datenschutzerklärung zur Kenntnis.</div></md-tooltip>
            </div>
            <div v-else class="cell text-center">
              <button class="button warn" type="submit" :disabled="sending || !agbConfirmed || !dsgvoConfirmed" @click="validateOrder">
                <i class="fi-plus"></i>&nbsp;&nbsp;&nbsp;Modul jetzt kostenfrei bestellen
              </button>
              <md-tooltip><div v-if="!agbConfirmed || !dsgvoConfirmed">Bitte stimmen Sie den AGB zu und nehmen Sie die Datenschutzerklärung zur Kenntnis.</div></md-tooltip>
            </div>
          </div>

          <div v-if="mode === 'cancel'" class="cell large-8 medium-6">
            <md-card style="min-width: 95%">
              <md-card-header>
                <div class="md-title">Kündigung Vertrag</div>
                <p class="md-subhead">Vertrag-Nr : <span>{{contract.contractNumber}}</span></p>
                <p class="md-subhead">Kunden-Nr : <span>{{contract.customerNo}}</span></p>
              </md-card-header>
              <md-card-content class="text-justify">
                Gemäß Ihres gewählten Zahlungsintervall (<b>{{labels.paymentInterval[contract.interval]}}</b>) werden wir
                die Kosten für Ihren Vertrag mit der Vertrag-Nr <b>{{contract.contractNumber}}</b> ab dem <b>{{contract.nextBillingPeriodStart | moment("DD. MMMM YYYY")}}</b> nicht mehr in Rechnung stellen.
                Bis dahin können Sie den Service noch uneingeschränkt nutzen.<br><br>
                <div class="md-subhead md-result-textarea md-alert"><b>ACHTUNG</b></div>
                Am <b>{{contract.nextBillingPeriodStart | moment("DD. MMMM YYYY")}}</b> werden wir Ihre selbst eingepflegten Daten vollständig löschen und die Nutzung von SoSE beenden!
                Ihr SoSE-Nutzerkonto wird Ihnen danach für weitere 3 Monate zur Verfügung stehen.
                Sie können in dieser Zeit noch alle Ihre bislang erhaltenen Rechnungen abrufen.
                Nach diesem Zeitraum wird dann ihr SoSE-Nutzerkonto vollständig gelöscht.
                <br><br>
                <div class="md-subhead md-result-textarea"><b>HINWEIS</b></div>
                Sie haben es sich in der Zwischenzeit anders überlegt und möchten Sie Ihre Kündigung zurückziehen?
                Kein Problem, auch das können Sie einfach hier in der Vertragsverwaltung erledigen.
              </md-card-content>
            </md-card>
            <br><br>
            <div class="cell" style="width: 95%; padding-left: 1rem">
              <div class="text-justify md-result-textarea">
                Im nächsten Schritt wird der bestehende <b>Vertrag Nr. {{contract.contractNumber}}</b> zwischen dem Träger
                <b>{{organization.name}}</b> und der <b>ai42 UG (haftungsbeschränkt)</b> zum <b>{{contract.nextBillingPeriodStart | moment("DD. MMMM YYYY")}}</b> beendet.
                Der Service wird bis diesem Zeitpunkt gemäß unseren AGB noch durchgeführt.
              </div>
              <br>
              <md-checkbox class="md-primary" v-model="agbConfirmed">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Ich habe die <a href="https://ai42.de/unternehmen/agb" target="_blank"><b>AGB</b></a> gelesen und bin damit einverstanden.
                <md-tooltip>Bitte lesen Sie unsere allgemeinen Geschäftsbedingungen (AGB) sorgfältig durch und stimmen Sie diesen zu.</md-tooltip>
              </span>
              <br>
              <md-checkbox class="md-primary" v-model="dsgvoConfirmed">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Die <a href="https://ai42.de/unternehmen/datenschutz" target="_blank"><b>Datenschutzerklärung</b></a> habe ich zur Kenntnis genommen.
                <md-tooltip>Bitte lesen Sie unsere Datenschutzerklärung sorgfältig durch und bestätigen Sie die Kenntisnahme.</md-tooltip>
              </span>
              <br>
              <md-checkbox class="md-primary" v-model="absoluteSure">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Ich bin mir absolut sicher und möchte den Vertrag kündigen.
                <md-tooltip>Bestätigen Sie bitte zusätzlich Ihre Kündigung.</md-tooltip>
              </span>
            </div>
            <br><br>

            <div class="cell text-center">
              <div class="grid-x">
                <div class="cell large-6 medium-6 small-6 text-right">
                  <button class="button alert" type="submit" :disabled="sending || !agbConfirmed || !dsgvoConfirmed || !absoluteSure" @click="validateOrder">
                    <md-icon class="order-confirm-icon">close</md-icon>&nbsp;&nbsp;&nbsp;Vertrag jetzt kündigen
                  </button>
                  <md-tooltip><div v-if="!agbConfirmed || !dsgvoConfirmed || !absoluteSure">Bitte stimmen Sie den AGB zu und nehmen Sie die Datenschutzerklärung zur Kenntnis. Bestätigen Sie bitte auch zusätzlich ihre Kündigung.</div></md-tooltip>
                </div>
                <div class="cell large-6 medium-6 small-6 text-left">
                  <button class="button success" style="margin-left: 1rem;" @click="closeDialog" type="button" :disabled="sending">
                    <md-icon class="order-confirm-icon">check</md-icon>&nbsp;&nbsp;&nbsp;Lieber nicht
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="module && mode === 'remove'" class="cell large-8 medium-6">
            <md-card style="min-width: 95%">
              <md-card-header>
                <div class="md-title">Abbestellung</div>
                <div class="md-subhead md-result-textarea"><b>{{module.label}}</b></div>
              </md-card-header>
              <md-card-content class="text-justify">
                Gemäß Ihres gewählten Zahlungsintervall (<b>{{labels.paymentInterval[contract.interval]}}</b>) werden wir
                die anteiligen Kosten für das Modul <b>{{module.label}}</b> ab dem <b>{{contract.nextBillingPeriodStart | moment("DD. MMMM YYYY")}}</b> nicht mehr in Rechnung stellen.
                Bis dahin können Sie den Service noch uneingeschränkt nutzen.<br><br>
                <div class="md-subhead md-result-textarea"><b>HINWEIS</b></div>
                Sie haben es sich in der Zwischenzeit anders überlegt und möchten Sie Ihre Abbestellung rückgängig machen?
                Kein Problem, auch das können Sie einfach hier in der Vertragsverwaltung erledigen.
              </md-card-content>
            </md-card>
            <br><br>
            <div class="cell" style="width: 95%; padding-left: 1rem">
              <div class="text-justify md-result-textarea">
                Im nächsten Schritt wird der bestehende <b>Vertrag Nr. {{contract.contractNumber}}</b> zwischen dem Träger
                <b>{{organization.name}}</b> und der <b>ai42 UG (haftungsbeschränkt)</b> zum <b>{{contract.nextBillingPeriodStart | moment("DD. MMMM YYYY")}}</b> angepasst.
                Der neue Service beinhaltet zu dem bestehenden Vertrag angepasste Leistungsmerkmale zu der regelmäßig
                vereinbarten Gebühr und wird gemäß unseren AGB durchgeführt.
              </div>
              <br>
              <md-checkbox class="md-primary" v-model="agbConfirmed">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Ich habe die <a href="https://ai42.de/unternehmen/agb" target="_blank"><b>AGB</b></a> gelesen und bin damit einverstanden.
                <md-tooltip>Bitte lesen Sie unsere allgemeinen Geschäftsbedingungen (AGB) sorgfältig durch und stimmen Sie diesen zu.</md-tooltip>
              </span>
              <br>
              <md-checkbox class="md-primary" v-model="dsgvoConfirmed">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Die <a href="https://ai42.de/unternehmen/datenschutz" target="_blank"><b>Datenschutzerklärung</b></a> habe ich zur Kenntnis genommen.
                <md-tooltip>Bitte lesen Sie unsere Datenschutzerklärung sorgfältig durch und bestätigen Sie die Kenntisnahme.</md-tooltip>
              </span>
              <br>
              <md-checkbox class="md-primary" v-model="absoluteSure">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Ich bin mir absolut sicher.
                <md-tooltip>Bestätigen Sie bitte zusätzlich Ihre Abbestellung.</md-tooltip>
              </span>
            </div>
            <br><br>

            <div class="cell text-center">
              <div class="grid-x">
                <div class="cell large-6 medium-6 small-6 text-right">
                  <button class="button alert" type="submit" :disabled="sending || !agbConfirmed || !dsgvoConfirmed || !absoluteSure" @click="validateOrder">
                    <md-icon class="order-confirm-icon">remove</md-icon>&nbsp;&nbsp;&nbsp;Modul jetzt abbestellen
                  </button>
                  <md-tooltip><div v-if="!agbConfirmed || !dsgvoConfirmed || !absoluteSure">Bitte stimmen Sie den AGB zu und nehmen Sie die Datenschutzerklärung zur Kenntnis. Bestätigen Sie bitte auch zusätzlich ihre Abbestellung.</div></md-tooltip>
                </div>
                <div class="cell large-6 medium-6 small-6 text-left">
                  <button class="button success" style="margin-left: 1rem;" @click="closeDialog" type="button" :disabled="sending">
                    <md-icon class="order-confirm-icon">check</md-icon>&nbsp;&nbsp;&nbsp;Lieber nicht
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="module && mode === 'undo_remove'" class="cell large-8 medium-6">
            <md-card style="min-width: 95%">
              <md-card-header>
                <div class="md-title">Abbestellung rückgängig machen</div>
                <div class="md-subhead md-result-textarea"><b>{{module.label}}</b></div>
              </md-card-header>
              <md-card-content class="text-justify">
                Gemäß Ihres gewählten Zahlungsintervall (<b>{{labels.paymentInterval[contract.interval]}}</b>) werden wir
                die anteiligen Kosten für das Modul <b>{{module.label}}</b> ab dem <b>{{contract.nextBillingPeriodStart | moment("DD. MMMM YYYY")}}</b> wie bisher weiterhin in Rechnung stellen.
                Sie können den Service damit uneingeschränkt in Zukunft nutzen.
              </md-card-content>
            </md-card>
            <br><br>
            <div class="cell text-center">
              <button class="button success" type="submit" :disabled="sending" @click="validateOrder">
                <md-icon class="order-confirm-icon">undo</md-icon>&nbsp;&nbsp;&nbsp;Abbestellung jetzt rückgängig machen
              </button>
            </div>
          </div>

          <div v-if="mode === 'undo_cancel'" class="cell large-8 medium-6">
            <md-card style="min-width: 95%">
              <md-card-header>
                <div class="md-title">Kündigung zurückziehen</div>
              </md-card-header>
              <md-card-content class="text-justify">
                Gemäß Ihres gewählten Zahlungsintervall (<b>{{labels.paymentInterval[contract.interval]}}</b>) werden wir
                die Kosten für Ihren Vertrag mit der Vertrag-Nr <b>{{contract.contractNumber}}</b> ab dem <b>{{contract.nextBillingPeriodStart | moment("DD. MMMM YYYY")}}</b> wie bisher weiterhin in Rechnung stellen.
                Sie können den Service damit uneingeschränkt im vereinbarten Vertragsumfang weiterhin in Zukunft nutzen.
              </md-card-content>
            </md-card>
            <br><br>
            <div class="cell text-center">
              <button class="button success" type="submit" :disabled="sending" @click="validateOrder">
                <md-icon style="color: black">undo</md-icon>&nbsp;&nbsp;&nbsp;Kündigung jetzt zurückziehen
              </button>
            </div>
          </div>

          <div v-if="contract && mode === 'change_max_children'" class="cell large-8 medium-6">
            <md-card style="min-width: 95%">
              <md-card-header>
                <div class="md-title">Anzahl maximaler Kinder anpassen</div>
                <div class="md-subhead md-result-textarea"><b>maximale Anzahl Kinder: {{contract.maxNumberOfChildren}}</b></div>
              </md-card-header>
              <md-card-content>
                <div class="grid-x">
                  <div class="cell large-3 medium-6 small-6 text-center">
                    <md-field>
                      <label>Anzahl Kinder (neu)
                        <md-tooltip>Maximale Anzahl Kinder, die mit SoSE verwaltet soll.<br>Der Preis wird entsprechend angepasst.</md-tooltip>
                      </label>
                      <md-input id="numChildren" type="number" @keyup="onSelectNumChildren()" @change="onSelectNumChildren()" v-model="newMaxNumberOfChildren"></md-input>
                    </md-field>
                  </div>
                  <div class="cell large-1 medium-6 small-6"></div>
                  <div class="cell large-8 medium-12 small-12">
                    <div v-if="viewValues" class="md-result-textarea">
                      <p v-if="viewValues.anteiligeKostenKinderBrutto">
                        <md-icon class="check-icon">check</md-icon>&nbsp;Restzahlung einmalig für das laufende Zahlungsintervall ({{labels.paymentInterval[contract.interval]}}) <strong>{{viewValues.kinderDelta}}</strong> Kinder für <strong>{{viewValues.anteiligeTage}}</strong> Tage:
                        <strong>{{viewValues.anteiligeKostenKinderBrutto / 100 | currency}}</strong>
                      </p>
                      <p>
                        <md-icon class="check-icon">check</md-icon>&nbsp;Gesamtkosten in den folgenden Zahlungsintervallen ({{labels.paymentInterval[contract.interval]}}):
                        <strong>{{viewValues.gesamtKostenBruttoFolgeIntervall / 100 | currency}}</strong>
                      </p>
                    </div>
                    <div v-else class="md-result-textarea"><br><i>Keine Veränderung / Angabe</i></div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
            <br><br>
            <div class="cell" style="width: 95%; padding-left: 1rem">
              <div class="text-justify md-result-textarea">
                Im nächsten Schritt wird der bestehende <b>Vertrag Nr. {{contract.contractNumber}}</b> zwischen dem Träger
                <b>{{organization.name}}</b> und der <b>ai42 UG (haftungsbeschränkt)</b> mit <b>sofortiger Wirksamkeit</b> angepasst.
                Der neue Service beinhaltet zu dem bestehenden Vertrag angepasste Leistungsmerkmale zu der regelmäßig
                vereinbarten Gebühr und wird gemäß unseren AGB durchgeführt.
              </div>
              <br>
              <md-checkbox class="md-primary" v-model="agbConfirmed">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Ich habe die <a href="https://ai42.de/unternehmen/agb" target="_blank"><b>AGB</b></a> gelesen und bin damit einverstanden.
                <md-tooltip>Bitte lesen Sie unsere allgemeinen Geschäftsbedingungen (AGB) sorgfältig durch und stimmen Sie diesen zu.</md-tooltip>
              </span>
              <br>
              <md-checkbox class="md-primary" v-model="dsgvoConfirmed">
              </md-checkbox>
              <span style="font-size: 0.9rem; position: relative;top: -4px;left: 8px;">Die <a href="https://ai42.de/unternehmen/datenschutz" target="_blank"><b>Datenschutzerklärung</b></a> habe ich zur Kenntnis genommen.
                <md-tooltip>Bitte lesen Sie unsere Datenschutzerklärung sorgfältig durch und bestätigen Sie die Kenntisnahme.</md-tooltip>
              </span>
            </div>
            <br><br>
            <div class="cell text-center">
              <button class="button success" type="submit" :disabled="sending || !agbConfirmed || !dsgvoConfirmed || newMaxNumberOfChildren < 1 || Number(newMaxNumberOfChildren) === Number(contract.maxNumberOfChildren)" @click="validateOrder">
                <md-icon class="order-confirm-icon">add</md-icon> / <md-icon class="order-confirm-icon">remove</md-icon>&nbsp;&nbsp;&nbsp;Anzahl maximaler Kinder jetzt anpassen
              </button>
            </div>
          </div>
        </div>
      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>

</template>

<script>

  import SessionTime from '../animations/SessionTime';
  import Vue from 'vue';
  import OrganizationService from '../../services/OrganizationService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  export default {
    name: 'OrderModuleDialog',

    components: {
      SessionTime,
    },

    props: {
      module: {
        required: true
      },
      contract: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      },
      priceLabels: {
        required: false
      },
    },

    watch: {
      showDialog() {
        this.agbConfirmed = false;
        this.dsgvoConfirmed = false;
        this.absoluteSure = false;
        this.newMaxNumberOfChildren = this.contract.maxNumberOfChildren;
        this.viewValues = null;
      },
    },

    data: () => ({
      success: true,
      sending: false,
      agbConfirmed: false,
      dsgvoConfirmed: false,
      absoluteSure: false,
      maxNumChildren: 1000,
      newMaxNumberOfChildren: 0,
      viewValues: null,
    }),

    methods: {

      getSelectedClass() {
        if (this.mode === 'add') {
          return 'selected-color'
        }
        return '';
      },

      addModule() {
        this.sending = true;
        this.success = false;

        OrganizationService.addModulesToOrganizationContract([this.module.type])
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$store.commit('successMsg', 'Die Bestellung des Moduls <b>' + this.module.label + '</b> wurde erfolgreich durchgeführt. Das Modul kann sofort verwendet werden.');
            this.$store.commit('organizationInfo', response.data);
            this.$emit("addModuleSuccess");
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Bestellen des Moduls <b>' + this.module.label + '</b>');
            this.sending = false;
          })
      },

      removeModule() {
        this.sending = true;
        this.success = false;

        OrganizationService.removeModulesFromOrganizationContract([this.module.type])
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$store.commit('successMsg', 'Die Abbestellung des Moduls <b>' + this.module.label + '</b> wurde erfolgreich durchgeführt. Das Modul kann ab dem <b>' + Vue.moment(this.contract.nextBillingPeriodStart).format("DD. MMMM YYYY") + '</b> nicht mehr verwendet werden.');
            this.$emit("removeModuleSuccess", response);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Abbestellen des Moduls <b>' + this.module + '</b>');
            this.sending = false;
          })
      },

      undoRemoveModule() {
        this.sending = true;
        this.success = false;

        OrganizationService.removeModulesFromOrganizationContract([this.module.type])
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$store.commit('successMsg', 'Die Abbestellung des Moduls <b>' + this.module.label + '</b> wurde erfolgreich rückgängig gemacht. Das Modul kann wieder uneingeschränkt verwendet werden.');
            this.$emit("undoRemoveModuleSuccess", response);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler bei der Rückgängigmachung der Abbestellung des Moduls <b>' + this.module + '</b>');
            this.sending = false;
          })
      },

      preCalcNumberOfChildren() {
        OrganizationService.preCalcNumberOfChildren(this.newMaxNumberOfChildren)
          .then((response) => {
            this.viewValues = response.data;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler bei der Preis-Berechnung der neuen Anzahl der Kinder');
          })
      },

      changeNumberOfChildren() {
        this.sending = true;
        this.success = false;

        OrganizationService.changeNumberOfChildren(this.newMaxNumberOfChildren)
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$store.commit('successMsg', 'Die Anzahl maximaler Kinder wurde erfolgreich auf <b>' + this.newMaxNumberOfChildren + '</b> angepasst.');
            this.$emit("changeNumberOfChildrenSuccess", response);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler bei der Anpassung der Anzahl der maximalen Kinder');
            this.sending = false;
          })
      },

      onSelectNumChildren() {
        if (this.newMaxNumberOfChildren > this.maxNumChildren) {
          this.newMaxNumberOfChildren = this.maxNumChildren;
        }
        if (this.newMaxNumberOfChildren > 0 && this.newMaxNumberOfChildren <= this.maxNumChildren) {
          while (this.newMaxNumberOfChildren.toString().startsWith('0') && this.newMaxNumberOfChildren.length > 0) {
            this.newMaxNumberOfChildren = this.newMaxNumberOfChildren.toString().substr(1);
          }
          if (Number(this.newMaxNumberOfChildren) !== Number(this.contract.maxNumberOfChildren)) {
            this.preCalcNumberOfChildren()
          } else {
            this.viewValues = null;
          }
        } else {
          this.viewValues = null;
        }
      },

      cancelContract() {
        this.sending = true;
        this.success = false;

        OrganizationService.cancelContract()
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$store.commit('successMsg', 'Die Kündigung des Vertrags <b>' + this.contract.contractNumber + '</b> wurde erfolgreich durchgeführt. SoSE kann ab dem <b>' + Vue.moment(this.contract.nextBillingPeriodStart).format("DD. MMMM YYYY") + '</b> nicht mehr verwendet werden.');
            this.$emit("cancelContractSuccess", response);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler bei der Kündigung des Vertrags <b>' + this.contract.contractNumber + '</b>');
            this.sending = false;
          })
      },

      undoCancelContract() {
        this.sending = true;
        this.success = false;

        OrganizationService.undoCancelContract()
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$store.commit('successMsg', 'Die Kündigung des Vertrags <b>' + this.contract.contractNumber + '</b>  wurde erfolgreich zurückgezogen. SoSE kann wieder uneingeschränkt im vereinbarten Vertragsumfang verwendet werden.');
            this.$emit("undoCancelContractSuccess", response);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Zurückziehen der Kündigung des Vertrags <b>' + this.contract.contractNumber + '</b>');
            this.sending = false;
          })
      },

      validateOrder() {
        if (this.agbConfirmed && this.dsgvoConfirmed) {

          if (this.mode === 'add') {
            this.addModule();
          }
        }

        if (this.agbConfirmed && this.dsgvoConfirmed && this.absoluteSure) {
          if (this.mode === 'remove') {
            this.removeModule();
          }
        }

        if (this.mode === 'undo_remove') {
          this.undoRemoveModule();
        }

        if (this.mode === 'change_max_children') {
          this.changeNumberOfChildren();
        }

        if (this.agbConfirmed && this.dsgvoConfirmed && this.absoluteSure) {
          if (this.mode === 'cancel') {
            this.cancelContract();
          }
        }

        if (this.mode === 'undo_cancel') {
          this.undoCancelContract();
        }
      },

      closeDialog() {
        this.$emit("closeOrderModuleDialog");
      }
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      organization() {
        return this.$store.getters.organization;
      },

      labels() {
        return this.$store.getters.labels;
      },
    },
  };
</script>

<style scoped>

  .md-result-textarea {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
  }

  .md-theme-default .md-result-textarea b {
    font-weight: 500;
    color: green;
  }

  .md-theme-dark .md-result-textarea b {
    font-weight: 500;
    color: lightgreen;
  }

  .md-alert b {
    color: red;
  }

  h5 {
    font-weight: 300;
  }

  .md-dialog /deep/.md-dialog-container {
    min-width: 90%;
    min-height: 85%;
  }

  .md-theme-default .selected-color {
    color: mediumvioletred;
    background: floralwhite;
  }

  .md-theme-dark .selected-color {
    color: orange;
    background: #588668;
  }

  .md-card {
    width: 230px;
    margin: 10px;
    display: inline-block;
    vertical-align: top;
  }

  .md-card .md-subhead  {
    opacity: 0.8;
    font-size: 13px;
  }

  .md-card-header .md-card-media {
    max-height: 0px;
    max-width: 40px;
    margin-left: 0px;
    margin-top: 8px;
    flex: 0 0 80px;
  }

  .md-card-header:first-child>.md-title:first-child {
    margin-top: 0;
  }

  .md-card-header.md-card-header-flex {
    display: flex;
    justify-content: space-between;
  }

  .md-card-actions {
    background: whitesmoke;
    justify-content: center;
    height: 50px;
  }

  .md-card-actions.selected-color {
    background: antiquewhite;
  }

  .md-content {
    max-height: 120px;
    overflow: auto;
    background: transparent;
  }

  .md-tooltip {
    font-size: large;
    text-align: left;
    height: auto;
    max-width: 380px;
    white-space: pre-wrap;
  }

  .md-tooltip b {
    color: greenyellow;
  }

  span {
    color: cornflowerblue;
  }

  .md-checkbox {
    margin: 8px 8px 8px 0;
  }

  .md-error-checkbox {
    color: red;
    font-size: 12px;
    margin-top: -30px;
    margin-left: 36px;
    position: relative;
    top: 15px;
  }

  .md-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    font-size: 20px!important;
    margin-top: -2px;
  }

</style>